.calcContainer {
  width: 100%;
  // padding: 0 5%;
  padding-top: 80px;
  display: flex;
  justify-content: center;

  form {
    margin: 0 5%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
}

@media screen and (max-width: 480px) {
  .calcContainer {
    padding-top: 0px;
    background: black;
    form {
      align-items: center;
    }
    button {
      width: 90%;
      height: 40px;
      border-radius: 5px;
      font-family: "Futura";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: capitalize;
      color: #ffffff;
      display: flex;

      div {
        width: unset;
      }
    }
  }
}
