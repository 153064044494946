.activationForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.phoneInput {
  width: 369px;
  height: 60px;
  margin-bottom: 50px;
  background: black !important;
  margin-left: 10px;
  color: #ffffff;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  :global {
    .ant-input-wrapper {
      height: inherit;
    }
    .ant-input,
    .ant-input-group-addon {
      height: inherit !important;
      background: black !important;
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
    }
    .ant-input-group-addon {
      color: #ffffff;
      width: 96px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      text-transform: capitalize;
    }
    .ant-input {
      margin-left: 10px;
      color: #ffffff;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      text-transform: capitalize;
    }
  }
}

.notification {
  background: #0d0d0d;
  color: #ffffff;
  :global {
    .ant-notification-notice-close {
      color: #ffffff;
    }
  }
}

.btnsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
