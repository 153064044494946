.mainContainer {
  max-width: 1440px;
  width: 100%;
  height: 800px;
  display: flex;
}
.txtContainer {
  width: 43%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  > div {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    width: 80%;
    justify-content: flex-start;
  }
  h2 {
    font-family: Great Vibes;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 45px;
    text-align: right;
    color: #ff8d4e;
    margin: 0;
    flex: 1 1 100%;
    text-align: left;
  }
  h1 {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 64px;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    flex: 1 1 100%;
    text-align: left;
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    color: #ffffff;
    margin: 10px 0;
    text-align: left;
  }
}

.recsContainer {
  width: 57%;
  height: 100%;
  position: relative;
  background-image: url('../../../assets/Images/sellBg.png');
  background-repeat: no-repeat;
  background-position: top right;
}

.rec1 {
  position: absolute;
  z-index: 3;
  width: 80.9px;
  height: 260px;
  left: 323px;
  top: 12px;
  background: rgba(0, 0, 0, 0.5);
  transform: skew(-15deg);
}
.rec2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  width: 166.07px;
  height: 500px;
  left: 68px;
  bottom: 28px;
  background: rgba(0, 0, 0, 0.5);
  transform: skew(-10.5deg);
}
.rec3 {
  position: absolute;
  z-index: 1;
  width: 166.07px;
  height: 497px;
  left: 35px;
  bottom: 5px;
  z-index: -1;
  background: #ff8d4e;
  transform: skew(-10.5deg);
}

//responsive
@media screen and (max-width: 1024px) {
  .txtContainer {
    justify-content: flex-start;
    padding-top: 105px;
  }
  .recsContainer {
    max-height: 771px;
    height: 100%;
    background-size: contain;
  }
  .rec1 {
    height: 192px;
    left: 256px;
    top: 12px;
  }
  .rec2 {
    height: 361px;
    bottom: 207px;
    left: 32px;
  }
  .rec3 {
    height: 360px;
    left: 0;
    bottom: 177px;
  }
}

@media screen and (max-width: 768px) {
  .mainContainer {
    height: 450px;
  }
  .txtContainer {
    h1 {
      font-size: 18px;
      line-height: 24px;
    }
    h2 {
      font-size: 16px;
      line-height: 21px;
    }
  }
  .recsContainer {
    max-width: 438px;
    max-height: 450px;
    height: 100%;
    width: 100%;
  }
  .rec1 {
    left: 150px;
    width: 40px;
    height: 140px;
  }
  .rec2 {
    bottom: 27px;
    width: 80.07px;
    height: 272px;
    left: 25px;
  }
  .rec3 {
    height: 272px;
    width: 99.07px;
    bottom: 0;
    left: 0;
  }
}

@media screen and (max-width: 480px) {
  .mainContainer {
    height: 350px;
  }
  .recsContainer {
    max-width: 280px;
    max-height: 322px;
    height: 100%;
    width: 100%;
    background-image: url('../../../assets/Images//Mobile/sellBg.png');
    background-position-x: left;
    background-size: initial;
  }
  .rec1 {
    left: 95px;
    width: 45px;
    height: 135px;
  }
  .rec2 {
    bottom: 0;
    width: 62.07px;
    height: 175px;
    left: 7%;
  }
  .rec3 {
    height: 170px;
    width: 62.07px;
    bottom: -10px;
  }
  .txtContainer {
    padding-top: 65px;
    button {
      display: none;
    }
  }
  .txtContainer > div:first-child {
    margin-bottom: 179px;
  }
  .txtContainer > div:not(:first-child) {
    width: 100vw;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    p {
      width: 100vw;
      margin: 0;
      font-size: 12px;
      line-height: 16px;
      padding: 0 5px;
    }
  }
}
