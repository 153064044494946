.pageContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}
.details {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  gap: 20px;
  align-items: center;
}

h1 {
  color: #ffffff;
}
.contacts {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  width: 531px;
}

.contacts h2 {
  color: #ffffff;
}

.contacts div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contacts div div {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .contacts {
    flex-direction: column;
    width: 100%;
  }
}
