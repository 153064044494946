.selectInput,
.selectInputEmpty,
.colorPicker,
.sellColorPicker {
  min-width: 231px;
  height: 60px;
  box-sizing: border-box !important;
}
.colorPicker,
.sellColorPicker,
.selectInputEmpty {
  background-color: #1e1e1e !important;
}
.selectInput > div,
.selectInputEmpty > div,
.colorPicker > div,
.sellColorPicker > div {
  width: inherit !important;
  height: inherit !important;
  background: transparent !important; //transparent?
  cursor: pointer;
}

.selectInput > span,
.selectInputEmpty > span,
.colorPicker > span,
.sellColorPicker > span {
  color: #ffffff !important;
}

.selectInput,
.selectInputEmpty,
.colorPicker,
.sellColorPicker {
  :global {
    .ant-select-selector {
      color: #ffffff;
      display: flex;
      align-items: center;
      text-align: left !important;
      cursor: default !important;
      border-right: none !important;
    }
    .ant-select-selection-search-input {
      height: 60px !important;
      text-align: left !important;
      cursor: default !important;
    }
    .ant-select-item-option-content {
      color: #ffffff !important;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
}

.dropDown {
  background: #0d0d0d;
  :global {
    .ant-select-item {
      background: #0d0d0d;
      color: #ffffff;
    }
    .ant-select-item-option-active {
      background: #156dc6;
      display: flex !important;
    }
    .ant-select-item-option-content {
      display: flex;
    }
  }
}

.colorPicker {
  width: 100%;
}
.sellColorPicker {
  flex: 1 1 45%;
  text-transform: capitalize;
}

.selectInputEmpty {
  border: 1px solid red !important;
}

//responsive
@media screen and (max-width: 768px) {
  .selectInput,
  .selectInputEmpty,
  .colorPicker,
  .sellColorPicker {
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .selectInput {
    min-width: auto;
  }
  .selectInput,
  .selectInputEmpty,
  .colorPicker,
  .sellColorPicker {
    :global {
      .ant-select-selection-search-input {
        height: 40px !important;
      }
    }
  }
}
