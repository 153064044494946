.pageContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  text-align: left;
}

.textContainer {
  margin-top: 50px;
  max-width: 1200px;
  p {
    text-align: start;
  }
  h2{
    color: white;
    text-align: left;
  }
  #map{
    width: 100%;
    height: 400px;
    margin-top: 50px;
    background: white;
  }
}
