.pageContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.firstSection {
  max-width: 1171px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.firstSection > div {
  flex: 1 1 45%; // change for smaller screens
}

//Carousel Block

.carouselContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-width: 50%;
  height: fit-content;
}

.icons {
  margin-top: -15px;
}

.bigPic {
  height: 445.5px;
  width: 100%;
  cursor: zoom-in;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
}

.carouselContainer {
  :global {
    .ant-carousel {
      width: 100%;
      height: 100%;
    }
    .slick-slider,
    .slick-list,
    .slick-track {
      height: inherit !important;
    }
    .slick-slide div {
      height: inherit;
    }
  }
}

.carouselItem {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.smallPicsWrapper {
  height: 141px;
  width: 100%;
  display: flex;
  overflow: hidden;
  gap: 25px;
}

.smallPicsWrapper > div {
  height: inherit;
  min-width: 178px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: zoom-in;
  outline: none;
}

.third {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.7;
  pointer-events: none;
}

.third > h1 {
  font-family: Futura;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 32px;
  letter-spacing: 0.06em;
  color: #ffffff;
  z-index: 1;
}

//info block

.carInfoContainer {
  width: 50%;
}

.carInfoContainer > div {
  background: #0d0d0d;
}

.carInfoContainer h1 {
  font-family: "Futura";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  text-align: left;
  letter-spacing: 0.06em;
  color: #ffffff;
}

.title {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
}

.title h1 {
  flex: 1 1 50%;
}

.title hr {
  flex: 1 1 100%;
}

.title p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.06em;
  color: #ffffff;
}

.year {
  font-family: "Futura";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  letter-spacing: 0.06em;
  color: #156dc6;
}

.yearMobile {
  display: none;
}

.info {
  display: flex;
  justify-content: space-evenly;
  text-align: left;
}

.info p,
.row p,
.purpose p {
  font-family: Futura;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #156dc6;
}
.price p {
  font-family: Futura;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.info h2,
.row h2 {
  font-family: Futura;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.infoColumn {
  max-width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  word-break: break-word;
  word-wrap: break-word;
}

.price {
  // display: flex;
  text-align: left;
  // gap: 20px;
  margin-top: 20px;
  > div {
    p,
    h1 {
      margin: 0;
    }
  }
  :global {
    .ant-collapse,
    .ant-collapse-content {
      background: transparent;
      border: none;
      .ant-collapse-item {
        border: none;
      }
    }
    .ant-collapse-header {
      color: #ffffff !important;
    }
    .ant-collapse-content-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      > div > div {
        display: flex;
        align-items: center;
      }
    }
  }
}

.priceLabels {
  > h1 {
    visibility: hidden;
  }
  > div {
    height: 44px;
  }
}
.price h1 {
  font-family: Futura;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ff8d4e;
  margin: 10px 0 !important;
}

.btnsWrapper {
  display: flex;
  background: transparent !important;
  justify-content: flex-end;
  gap: 15px;
  margin-top: -55px;
  margin-right: 15px;
}
.row {
  display: flex;
  justify-content: flex-start;
  padding: 0 20px;
  gap: 20px;
  margin-top: 20px;
  > div {
    text-align: start;
    margin-left: 20px;
  }
}
.row_2 {
  margin: 20px 33px;
}

.purpose {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  p {
    flex: 1 1 100%;
    text-align: start;
  }
  :global {
    .ant-radio-group {
      width: 100%;
      height: 40px;
      text-align: start;
    }
    .ant-radio {
      width: 20px;
      height: 20px;
    }
    .ant-radio-inner {
      width: inherit;
      height: inherit;
      background: transparent;
    }

    .ant-radio-inner::after {
      top: 4.5px !important;
      left: 5px !important;
    }
    .ant-radio-wrapper {
      color: #ffffff;
    }
    input {
      display: none !important;
    }
  }
}

.formContainer {
  flex: 0 1 50% !important;
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  textarea {
    width: 100%;
    height: 238px;
    background: #0d0d0d;
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: rgba(255, 255, 255, 0.8);
    resize: none;
  }
}

//media queries
@media only screen and (max-width: 768px) {
  .firstSection {
    justify-content: center;
  }
  .firstSection > div {
    flex: 1 1 100%; // change for smaller screens
  }
  .carouselContainer,
  .carInfoContainer,
  .formContainer {
    max-width: 95%;
  }
  .bigPic {
    clip-path: none;
  }
  .smallPicsWrapper {
    display: none;
  }
  .icons {
    z-index: 1;
    margin: 0;
    position: absolute;
    height: 25px;
  }
  .carouselContainer {
    position: relative;
    :nth-child(2) {
      left: 5px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      display: flex;
      align-items: center;
    }
    :nth-child(3) {
      right: 5px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      display: flex;
      align-items: center;
    }
  }
  .formContainer {
    flex: 1 1 100% !important;
  }
}
@media only screen and (max-width: 480px) {
  .carouselContainer,
  .carInfoContainer,
  .formContainer {
    max-width: 100%;
  }
  .bigPic {
    width: 100%;
    max-height: 348px;
  }

  .title {
    margin-bottom: 10px;
    p {
      flex: 1 1 50%;
      text-align: start;
    }
  }
  .info {
    flex-direction: column;
    padding: 0 20px 40px 20px;
    hr {
      display: none;
    }
  }
  .infoColumn {
    max-width: 100%;
    gap: 5px;
    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 12px;
        line-height: 16px;
      }
      h2 {
        margin: 0;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
  .formContainer {
    flex: 1 1 100% !important;
  }

  .year {
    display: none;
  }
  .yearMobile {
    display: inline-block;
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #156dc6;
  }

  .btnsWrapper {
    margin-top: -27px;
  }
}
