$primary: #4a4a4a;
$secondary: #ffffff;
$arrow-size: 12px;
$arrow-distance: 20px;
$vertical-space: 10px;
$readmore-color: #dbdbdb;

.product-carousel {
  border-top: 1px solid $primary;
  border-bottom: 1px solid $primary;
  padding: 40px 40px;
  width: 100%;
  position: relative;
  font-family: "Montserrat arm", sans-serif;
  background-color: #0f0f0f;

  .product-carousel-title {
    font-family: " Montserrat arm";
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 3%;
  }
  .product-carousel-read-more {
    font-family: "Montserrat arm";
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-top: 2%;
    background: none;
    border: none;
    color: $readmore-color;
  }
  .indicators {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .indicator {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $secondary;
      margin: 0 5px;
      transition: background-color 0.3s;

      &.active {
        width: 18px;
        height: 10px;
        border-radius: 5px;
      }
    }
  }

  .product {
    box-sizing: border-box;
    margin: 0 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    .product-top {
      margin: 0 5%;
      border-radius: 10px;
      position: relative;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        padding: 2px;
        background: linear-gradient(110.14deg, #7b7b7b 1.34%, #3a3a3a 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: subtract;
      }

      .product-image {
        width: auto;
        height: auto;
        aspect-ratio: 16 / 9;
        padding: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: inherit;
        }
      }
    }

    .product-bottom {
      margin-top: auto;

      .product-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: "Montserrat arm", sans-serif;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        padding: 5%;

        .product-name,
        .product-prices {
          margin: 0;
        }
      }
    }
  }

  .slick-prev,
  .slick-next {
    border: 2px solid #156dc6;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: $secondary;
    margin-bottom: 5%;
  }
}

.slick-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-prev,
.slick-next:hover {
  cursor: pointer;
}
.slick-list {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
  }
}

.slick-track {
  display: flex;
  position: relative;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-arrow.slick-hidden {
  display: none;
}



// Media queries

@media screen and (max-width: 768px)  {
  .slick-prev,
  .slick-next {
    margin: 0;
  }
  
  .slick-slider {
    gap: 10px;
  }

  .product-carousel {
    padding: 20px;
  }

  .product-carousel .product {
    margin: 0;
  }

  .product-carousel .product .product-top {
    margin: 0;
  }

}
