.activation {
  flex: 1 1 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  h1 {
    flex: 1 1 100%;
  }
  button {
    max-width: 150px;
    flex: 1 1 50%;
  }
}

@media screen and (max-width: 768px) {
  .activation {
    justify-content: flex-start;
    text-align: start;
    gap: 10px;
    margin-top: 10px;
    h1 {
      font-size: 18px;
      margin: 0;
    }
    button {
      font-size: 12px;
    }
  }
}
