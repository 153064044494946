.loaderWrapper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(black, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.hidden {
  display: none;
}
.lds_ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds_ripple div {
  position: absolute;
  border: 4px solid #156dc6;
  opacity: 1;
  border-radius: 50%;
  animation: lds_ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds_ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds_ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
