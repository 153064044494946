@font-face {
  font-family: 'Great Vibes';
  src: local('Great Vibes'), url(./fonts/GreatVibes-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Futura';
  src: local('Futura'), url(./fonts/Futura.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url(./fonts/Roboto-Regular.ttf) format('truetype');
}

.App {
  text-align: center;
  overflow-x: visible;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  :global {
    .ant-popover-open {
      display: none !important;
    }
  }
}

body {
  margin: 0 auto !important;
  padding: 0;
  background: #101010;
  color: #ffffff;
  // overflow-x: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #ffffff;
}

.ant-select-selector {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

p {
  cursor: default;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: none !important;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
}
