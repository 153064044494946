.activationForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.emailInput {
  width: 369px;
  height: 60px;
  margin-bottom: 50px;
  background: black !important;
  margin-left: 10px;
  color: #ffffff;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1em;
  // text-transform: capitalize;
}

.btnsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.notification {
  background: #0d0d0d;
  color: #ffffff;
  :global {
    .ant-notification-notice-close {
      color: #ffffff;
    }
  }
}
