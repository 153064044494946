.alert {
  width: 460px;
  align-items: center;
  background: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.5s ease-in-out;
  :global {
    .anticon-close {
      color: #ffffff !important;
    }
    .anticon-close:hover {
      color: #ffffff !important;
    }
  }
}
.popover {
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  :global {
    .ant-popover-inner {
      background: transparent;
    }
    .ant-alert {
      background: #0d0d0d;
    }
    .ant-alert-error {
      border-color: red !important;
    }
  }
}

//popconfirm
.popConfirm {
  font-family: Futura;
  max-width: 480px !important;
  width: 100% !important;
  :global {
    .ant-popover-arrow,
    .ant-popover-inner {
      background: #0d0d0d;
      border-color: #156dc6 !important;
    }
    .ant-popover-message-title {
      color: #ffffff;
    }

    .ant-btn-primary {
      background: #156dc6 !important;
      border-color: #156dc6 !important;
    }
    .ant-popover-inner-content {
      max-width: 480px !important;
      width: 100% !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      flex-direction: column !important;
      input {
        width: 100% !important;
        background: transparent;
        border: none;
        border-bottom: 1px solid white;
        text-align: start;
        font-family: Roboto;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.1em;
        color: rgba(255, 255, 255, 0.8);
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }
  span {
    margin: 0;
  }

  p {
    color: #ffffff;
  }
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  button {
    max-width: 100px;
    height: 40px;
  }
  button:first-child {
    color: #156dc6;
    background: #ffffff;
  }
}

@media screen and (max-width: 768px) {
  .popover {
    display: block;
    left: auto !important;
    padding: 0 !important;
    :global {
      .ant-popover-inner-content {
        padding: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }
  .alert {
    max-width: 80% !important;
  }
}
