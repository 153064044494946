.pageContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.textContainer {
  margin-top: 50px;
  max-width: 1200px;
  p {
    text-align: start;
  }
  h2{
    color: white;
  }
}
