.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 99.5px;
  height: 96px;
  position: absolute;
  left: -100px;
  background: #ff8d4e;
  -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 5;
}

.showIcon {
  left: 0;
}

@media screen and (max-width: 480px) {
  .iconContainer {
    width: 72px;
    height: 47px;
    bottom: 10%;
  }
  .showIcon {
    width: 72px;
    height: 47px;
    bottom: 10%;
    > span:first-child > svg {
      height: 33px;
    }
    > span:nth-child(2) > svg {
      width: 40px;
    }
  }
}
