.pageContainer {
  width: 100%;
  height: 800px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  background-image: url('../../assets/Images/login.png');
  background-repeat: no-repeat;
  background-position: top left;
  overflow: hidden;
}

.recsContainer {
  width: 57%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 360px;
    margin-right: 160px;
    margin-top: 70px;
    img {
      margin-bottom: 50px;
    }
    p {
      font-family: Futura;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      text-align: right;
      letter-spacing: 0.165em;
      color: #ffffff;
    }
    h1 {
      font-family: Futura;
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 65px;
      letter-spacing: 0.165em;
      color: #ffffff;
      text-align: left;
    }
  }
}

.rec1 {
  position: absolute;
  z-index: 3;
  width: 134.9px;
  height: 275px;
  right: 280px;
  top: 0px;
  background: rgba(0, 0, 0, 0.5);
  -webkit-clip-path: polygon(0 0, 50% 0, 100% 100%, 50% 100%);
  clip-path: polygon(0 0, 50% 0, 100% 100%, 50% 100%);
}
.rec2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  width: 253.07px;
  height: 498px;
  right: 28px;
  bottom: 28px;
  background: rgba(0, 0, 0, 0.5);
  -webkit-clip-path: polygon(0 0, 62% 0, 99% 100%, 38% 100%);
  clip-path: polygon(0 0, 62% 0, 99% 100%, 38% 100%);
}
.rec3 {
  position: absolute;
  z-index: 1;
  width: 253.07px;
  height: 497px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  background: #ff8d4e;
  -webkit-clip-path: polygon(0 0, 62% 0, 100% 100%, 38% 100%);
  clip-path: polygon(0 0, 62% 0, 100% 100%, 38% 100%);
}

.formContainer {
  width: 43%;
  height: 100%;
  display: flex;
  justify-content: center;
  .formStyle {
    width: 460px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .titles {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      h1 {
        font-family: Futura;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 38px;
        text-align: right;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #ffffff;
        cursor: pointer;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      gap: 20px;
      div {
        display: flex;
        gap: 20px;
      }
    }
    input {
      height: 60px;
      max-width: 460px;
      border: 1px solid #ff8d4e;
      box-sizing: border-box;
      background: transparent;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      color: rgba(255, 255, 255, 0.8);
    }
    span {
      transition: all 0.2s ease-in-out;
    }
  }
}

.signup {
  margin-left: -40.5% !important;
}

.signin {
  margin-left: 40.5% !important;
}

//alert and popover
.alert {
  max-width: 460px;
  align-items: center;
  background: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.5s ease-in-out;
  :global {
    .anticon-close {
      color: #ffffff !important;
    }
    .anticon-close:hover {
      color: #ffffff !important;
    }
  }
}
.popover {
  background: transparent;
  display: flex;
  justify-content: center;
  :global {
    .ant-popover-inner {
      background: transparent;
    }
    .ant-alert-error {
      border-color: red !important;
    }
    .ant-popover-arrow {
      display: none !important;
    }
  }
}

.signup {
  margin-left: -37% !important;
}
.signupArm {
  margin-left: -35% !important;
}
.signupRu {
  margin-left: -30% !important;
}
.signin {
  margin-left: 40% !important;
}
.signinArm {
  margin-left: 47%;
}
.signinRu {
  margin-left: 54%;
}

//
@media screen and (max-width: 768px) {
  .alert {
    max-width: 350px !important;
  }
}
