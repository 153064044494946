.footerContainer {
  min-height: 400px;
  background: #0000;
  display: flex;
  justify-content: center;
  margin-top: auto;

  @media screen and (max-width: 480px) {
    min-height: auto;
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.footerCategories {
  display: flex;
  :global {
    .ant-menu,
    .ant-menu-item-selected {
      color: #ffffff;
      background: #0d0d0d;
      border: none;
    }
    .ant-menu-item {
      font-family: "Futura";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-align: right;
      letter-spacing: 0.06em;
      border: none;
    }
    .ant-menu-item:hover {
      color: #156dc6 !important;
      border: none !important;
    }
    .ant-menu-item-selected {
      color: #ffffff !important;
      border: none !important;
    }
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contacts h2 {
  color: #ffffff;
}

.contacts div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contacts div div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 10px;
}

.iconsContainer {
  display: flex;
  h1 {
    display: none;
  }
}
.phoneWrapper {
  p {
    text-align: center;
    margin: 0;
    width: max-content;
    font-size: 1rem;
    color: #ffffff;
    padding: 1rem 0;
    span {
      font-size: 1rem;
      color: #ffffff;
      padding-left: 1rem;
    }
  }
}
.address,
.email {
  display: flex;
}
.email {
  p {
    text-decoration: underline;
  }
}
@media (max-width: 768px) {
  * {
    img,
    a {
      width: unset;
    }
  }
  .innerContainer {
    flex-direction: column;
  }
  .footerCategories {
    flex-direction: column;
    button {
      margin: 2% 0;
      span {
        font-size: 1rem;
        padding: 2% 0;
      }
    }
    :global {
      .ant-menu {
      }
      .ant-menu-item:hover {
        color: #156dc6 !important;
        border: none !important;
        ::after {
          border-bottom: none !important;
          color: none !important;
        }
      }
      .ant-menu-item:hover::after {
        border-bottom: none !important;
      }
      .ant-menu-item-selected {
        color: #ffffff !important;
        border: none !important;
        background: #0d0d0d !important;
      }
      .ant-menu-item {
        :after,
        :hover:after {
          border: none !important;
          color: none !important;
        }
      }
    }
  }
  .contacts {
    align-items: center;
    flex-direction: column;
    padding-left: 22px;
    position: relative;
    margin: 5% 0;
    > div {
      align-items: flex-start;
    }
  }
  .address,
  .email {
    p {
      font-size: 1rem;
    }
  }
  .address {
    p {
      text-align: start;
    }
  }
  .address,
  .email {
    img {
      width: 1rem;
      height: 1rem;
    }
  }
  .iconsContainer {
    flex-wrap: wrap;
    justify-content: center;
    h1 {
      display: inline-block;
      font-family: Futura;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.06em;
      color: #ff8d4e;
      flex: 1 1 100%;
    }
  }
  .iconsWrapper {
    height: 202px;
    padding: 10% 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .footerCategories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .contacts {
    flex-direction: column;
  }
  .phoneWrapper p {
    width: unset;
  }
}
