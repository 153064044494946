.section1Container {
  width: 47%;
  label {
    display: flex;
    font-family: "Futura";
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 12px;
    letter-spacing: 0.1em;
    color: #ffffff;
    margin: 10px 0;
  }
}

.dropDown {
  background: #0d0d0d;

  :global {
    .ant-select-item {
      background: #0d0d0d;
      color: #ffffff;
    }

    .ant-select-item-option-active {
      background: #156dc6;
      display: flex !important;
    }

    .ant-select-item-option-content {
      display: flex;
    }
  }
}

.firstColumn,
.secondColumn {
  width: 100%;
  // display: inline-block !important;
}

.secondColumn {
  width: 100%;
  margin-left: 2%;
}

.requiredMark {
  color: red;
  font-size: 22px;
}

.flexContainer {
  display: flex;
}

.radioButton {
  border-radius: 10px;
}

.errorBorder {
  border: 1px solid red;
}

:global {
  .ant-radio-group,
  .ant-radio-group-outline {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    font-size: 0;
    line-height: unset;
    display: flex;
    .ant-radio-button-wrapper {
      flex: 1 1 50%;
      position: relative;
      margin: 0;
      padding: 0 15px;
      cursor: pointer;
      transition: color 0.3s, background 0.3s, border-color 0.3s,
        box-shadow 0.3s;
      height: 56px;
      line-height: 56px;
      background: #181818;
      border: 2px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      color: #ffffff;
      text-align: center;
      font-size: 18px;
      border-radius: 10px;
      &:first-child {
        margin-right: 2rem;
      }
      span {
        font-size: 0.6rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
      border-color: #ff8d4e !important;
      color: #ffffff;
      background: rgba(255, 141, 78, 0.13);
    }
    .ant-radio-button-wrapper::before {
      display: none !important;
    }
  }
}
@media (max-width: 1199.98px) {
  .section1Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .flexContainer {
    width: 100%;
  }
  .personType {
    width: 100%;
    input {
      width: 100%;
    }
  }
  :global {
    .ant-radio-group,
    .ant-radio-group-outline {
      .ant-radio-button-wrapper {
        line-height: normal;
        span {
          font-size: 0.6rem;
          line-height: 3.2rem;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .section1Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .section1Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    > div {
      display: flex;
      flex-wrap: wrap;
      font-size: 8px;
      justify-content: center;
      width: 343px !important;

      label {
        display: block;
        text-align: left;
        // flex: 1 1 100%;
        font-size: 0.5rem;
        line-height: 15px;
        margin-bottom: 5px;
      }
    }
    .personType {
      width: 85%;
      display: block;
    }
    .firstColumn,
    .secondColumn {
      margin: 0;

      input {
        width: 100%;
      }
    }
    Option {
      color: #dddddd;
    }
  }
  :global {
    .ant-radio-group,
    .ant-radio-group-outline {
      .ant-radio-button-wrapper {
        font-size: 7px;
      }
    }
    .ant-select-item-option-state {
      flex: 1;
    }
  }
}
