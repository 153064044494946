$background_color: #101010;

.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;

  background: $background_color;

  .screenContanier {
    width: 90%;
    align-items: flex-start;
    display: flex;
  }
}
.list {
  width: 100%;
}
.carsContainer {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin: 0 5%;
}

.formContainer {
  max-width: 1200px;
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
}

.form {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  min-height: 270px;
}

.form > div {
  transition: 1s all ease-in-out;
  max-width: 1200px;
}

.formWrapper > form {
  z-index: 2;
  background: #0d0d0d;
  border: 1px solid rgba(255, 255, 255, 0.8);
  padding: 24px;
  margin-bottom: 1rem;
}

.hiddenForm {
  margin-top: -100%;
  z-index: -1;
}
.hiddenForm > form {
  background: #0d0d0d;
  border: 1px solid rgba(255, 255, 255, 0.8);
  padding: 24px;
}

.smallFormHidden {
  margin-top: -100%;
  height: 269px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.smallForm {
  max-height: 269px;
  width: 100%;
  background: #0d0d0d;
  border: 1px solid rgba(255, 255, 255, 0.4);
  margin-top: 0%;
}

.activeIcon {
  svg {
    rect {
      stroke: #ff8d4e;
    }
  }
}

//cars section
.carsContainer {
  max-width: 1200px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

//pagination
.pagination {
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.pagination > li {
  background: transparent !important;
  border: none;
  width: 15px;
  height: 32px;
  margin: 0;
}

.pagination > li > a {
  font-family: "Futura";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  letter-spacing: 0.06em;
  color: #ffffff;
}

.pagination > li > button {
  background: transparent !important;
  border: none !important;
  color: #ffffff !important;
  font-size: 24px !important;
}
.pagination > li > button:disabled {
  color: #424242 !important;
}

.pagination {
  :global {
    .ant-pagination-item-active > a {
      transition: all 0.2s;
      color: #ff8d4e !important;
      font-weight: 400;
      text-align: center;
    }
    .ant-pagination-item:hover > a {
      color: #ff8d4e !important;
    }
  }
}
.iconsWrapper {
  display: none;
}
.lineStyle {
  border-bottom: 1px solid #272727;
  margin: 0 2%;
  height: 1px;
}
.hotOffers {
  margin-top: 32px;
}
.filterWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  svg {
    width: 40px;
  }

  h1 {
    text-align: left;
  }
}


//responsive

@media screen and (max-width: 1200px) {
  .formContainer {
    margin-top: 60px;
  }
  .carsContainer {
    margin-top: 20px;
    > div {
      margin-top: 10px;
    }
  }
  .form {
    display: none;
  }
  .btnsWrapper {
    width: 100vw;
    background: #0d0d0d;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .iconsWrapper {
    display: flex;
  }
  .pagination {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

@media (max-width: 1199.98px) {
  .screenContanier {
    flex-direction: column;
    align-items: flex-end !important;
  }
}
