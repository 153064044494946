.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Full height to cover the screen */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalContent {
  background-color: #101010;
  box-shadow: 0px 7px 10px 0px #00000029;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
  max-width: 90%;
  position: relative;
  max-height: 90%;
  overflow-y: auto;
}

.modalClose {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
