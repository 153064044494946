.search {
  position: absolute;
  width: 476px;
  height: 548px;
  left: 135px;
  top: 100px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  z-index: 10;
  :global {
    .ant-select {
      border: 1px solid rgba(255, 255, 255, 0.329) !important;
    }
    .ant-select-selection-placeholder {
      color: rgba(255, 255, 255, 0.877);
    }
    .ant-select-disabled {
      border: 1px solid rgba(255, 255, 255, 0.082) !important;
      .ant-select-selection-placeholder {
        color: rgba(255, 255, 255, 0.575);
      }
    }
  }
}

.search p {
  margin: 0 auto 20px auto;
}

.search .section1 {
  width: 100% !important;
}
.search .section1 div:nth-child(2) {
  margin-bottom: -10px;
}

.label,
.priceLabel,
.milLabel {
  text-align: left;
  font-family: 'Futura';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #ffffff;
  display: flex;
  align-items: flex-end;
}

.underlinedText,
.underlinedTextClear {
  width: fit-content;
  height: 21px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.07em;
  text-decoration-line: underline;
  color: #ffffff;
  cursor: pointer !important;
}
.underlinedTextClear {
  color: #ff8d4e !important;
}
//big search

.bigWrapper {
  display: flex;
  width: inherit;
  :global {
    .ant-select {
      border: 1px solid rgba(255, 255, 255, 0.329);
    }
    .ant-select-selection-placeholder {
      color: rgba(255, 255, 255, 0.877);
    }
    .ant-select-disabled {
      border: 1px solid rgba(255, 255, 255, 0.082) !important;
      .ant-select-selection-placeholder {
        color: rgba(255, 255, 255, 0.575);
      }
    }
  }
}

.section1,
.section2 {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 50%;
  height: 100%;
}

.section1 > label,
.section2 > label,
.bodyTypes > label {
  flex: 0 0 100%;
}

.section1 > div:nth-child(2) {
  flex: 1 1 100% !important;
}

.section1 div,
.switches div,
.section2 > div:nth-child(2),
.section2 > div:nth-child(3),
.section2 > div:nth-child(4),
.section2 > div:nth-child(5) {
  flex: 1 1 45%;
}

//Body Types

.bodyTypes {
  // height: 137px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.bodyTypes > div > input {
  height: 75px;
  min-width: 131px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #0d0d0d;
  cursor: pointer;
  font-size: 0;
  border: 1px solid transparent;
  outline: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    outline: none;
    border: 1px solid #ff8d4e;
  }
}

.selected {
  border: 1px solid #ff8d4e !important;
}

.bodyTypes div p {
  text-align: center;
  font-family: 'Futura';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.06em;
}

.switchesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0;
}
.switchesWrapper {
  :global {
    .ant-switch {
      background-color: #2d2d2d;
    }
    .ant-switch-checked {
      background: #156dc6;
    }
    .ant-switch-handle::before {
      background-color: #ffffff;
    }
  }
}

.switchesWrapper > div {
  height: 59px;
  width: 200px;
  flex: 1 1 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.switchesWrapper > div > p {
  font-family: Futura;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  margin-bottom: 0;
}

.switchesWrapper > div > button {
  margin-left: 5px;
}

.switchesWrapper > div > input {
  display: none;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
}

// simple form for cars page

.selectWrapper,
.labelsWrapper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin: 15px 20px;
}

.selectWrapper > div:first-child,
.selectWrapper > div:nth-child(2) {
  flex: 1 1 25%;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 45px 20px 0 0;
  z-index: 50;
  gap: 20px;
}
.labelsWrapper > label:first-child {
  flex: 0 0 60%;
}

//seller form

.section1 {
  input {
    height: 60px;
    // cursor: default;
  }
  :global {
    .ant-input-number {
      background: #1e1e1e;
      height: 60px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      color: #ffffff;
    }
    .ant-input-number-handler-wrap {
      display: none !important;
    }
  }
}

//label and select
.labelWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 100% !important;
}

.otherLabelWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 100% !important;
}

.labelWrapper > .priceLabel {
  flex: 0 0 30% !important;
}

.labelWrapper > .milLabel {
  flex: 0 0 30% !important;
}

.labelSelect {
  flex: 0 0 15% !important;
  border: none !important;

  :global {
    .ant-select-selector {
      background-color: #0d0d0d !important;
      color: #ffffff;
    }
    .ant-select-arrow {
      color: #ffffff;
    }
  }
}

.dropDown {
  background: #0d0d0d;
  :global {
    .ant-select-item {
      background: #0d0d0d;
      color: #ffffff;
    }
    .ant-select-item-option-active {
      background: #156dc6;
      display: flex !important;
    }
    .ant-select-item-option-content {
      display: flex;
    }
  }
}

.other {
  width: 131px;
  text-align: end;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #156dc6;
  cursor: pointer;
  margin-top: -24px;
  margin-bottom: 10px;
  margin-right: 5%;
}

.asterisk {
  color: red;
  font-size: 22px;
}
//responsive

@media screen and (max-width: 1024px) {
  .search {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .search {
    display: none;
  }
  .buttons {
    justify-content: center;
    button {
      width: 102px;
      height: 30px;
    }
    p {
      margin: 0;
    }
  }
  .section1 {
    input {
      height: 40px !important;
    }
    :global {
      .ant-input-number {
        height: 40px !important;
      }
    }
  }
  .switchesWrapper > div {
    width: 100px;
    flex: 1 1 35%;
    flex-wrap: wrap;
  }
  .label,
  .priceLabel,
  .milLabel {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .bigWrapper {
    flex-direction: column;

    hr {
      display: none;
    }
  }
  .section1,
  .section2 {
    width: 100%;
  }
  .section1 div,
  .section2 > div:nth-child(2),
  .section2 > div:nth-child(3),
  .section2 > div:nth-child(4),
  .section2 > div:nth-child(5) {
    flex: 1 1 30%;
  }
  .section2 {
    margin-top: 10px;
  }
  .section2 > div {
    min-width: 181px;
  }
  .labelBodyType {
    height: 35px;
    max-width: 375px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    background-color: rgb(30, 30, 30);
    z-index: 5;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  .bodyTypesContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .bodyTypes {
    margin-top: -463px;
    transition: all 0.5s ease-in-out;
  }
  .other {
    margin-top: 5px;
  }
  .arrowDown {
    transition: all 0.5s ease-in-out;
  }
}
