.questions {
  width: 100%;
  background: transparent;
  h1 {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    color: #ffffff;
  }
  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.1em;
    color: #ffffff;
    text-align: start;
  }
  label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-transform: capitalize;
    color: #ffffff;
  }

  :global {
    .ant-radio-group {
      width: 100%;
      height: 40px;
      text-align: start;
    }
    .ant-radio {
      width: 20px;
      height: 20px;
    }
    .ant-radio-inner {
      // border-radius: 0 !important;
      width: inherit;
      height: inherit;
      background: transparent;
    }
    .ant-radio-inner::after {
      top: 4.5px !important;
      left: 5px !important;
    }
    input {
      display: none !important;
    }
  }
}
.asterisk {
  color: red;
  font-size: 22px;
}
@media screen and (max-width: 768px) {
  .questions {
    h1 {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.1em;
      color: #ff8d4e;
      text-align: start;
    }
    h2,
    label {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
