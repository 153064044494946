.pageContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
}

.pageContainer > h1 {
  font-family: Futura;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 65px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-top: 20px;
}

.compareContainer {
  // border: 1px solid white;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 70px;
}

.compareImgs {
  min-height: 300px;
  max-height: 300px;
  display: flex;
  margin: 20px 0px;
  div {
    flex-basis: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.compareLines {
  min-height: 100px;
  max-height: 100px;
  display: flex;
  margin: 10px 0;
  div {
    flex-basis: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0d0d0d;
    h1 {
      font-family: Futura;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.06em;
      color: #156dc6;
      margin: 0;
    }
    h2 {
      font-family: Futura;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 0.06em;
      color: #ffffff;
      margin: 0;
    }
  }
}

.carPic {
  position: relative;
  span {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}

//responsive
@media screen and (max-width: 480px) {
  .pageContainer > h1 {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.165em;
    color: #ff8d4e;
  }
  .compareImgs {
    min-height: 220px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100vw;
    gap: 5px;
    div {
      img {
        height: 167px;
      }
    }
  }
  .compareImgs div {
    flex: 0 0 auto;
  }
  .compareImgs div:first-child {
    display: none;
  }
  .carPic {
    width: 167px;
    height: 167px;
    flex-direction: column;
    h1 {
      font-family: Futura;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.06em;
      color: #ffffff;
    }
    h2 {
      font-family: Futura;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.06em;
      color: #ffffff;
    }
  }

  .compareLines {
    min-height: 50px;
    max-height: 50px;
    margin: 3px 0;
    gap: 3px;
    div {
      h1 {
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
      }
      h2 {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
  .compareLines div:nth-child(2) {
    flex-basis: 75%;
    justify-content: flex-start;
    h2 {
      margin-left: 10px;
    }
  }
}
