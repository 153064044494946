:global {
  .ant-menu-submenu {
    max-width: 200px !important;
  }
  .ant-menu-submenu ul {
    padding: 10px 0 !important;
  }
  .ant-menu-submenu li {
    height: auto !important;
    gap: 8px;
    margin: 0 !important;
  }
  .ant-menu-submenu li:hover {
    background: #ff8d4e !important;
  }

}
.ant-menu-submenu li {
  font-family: "Futura";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: start;
  letter-spacing: 0.165em;
  text-transform: uppercase;
  border: none !important;
  img {
    margin-right: 10px;
  }
}

.languageName {
  color: #000;
}

.span {
  display: flex;
  align-items: center;
  margin: 0;
  font-family: "Montserrat arm", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  height: 46px;
  text-align: center;
  text-transform: capitalize;
  border: none !important;
  :global {
    .anticon {
      margin: 0;
    }
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
}
