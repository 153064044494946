.modal,
.loginSignup,
.activationModal {
  width: auto;
  :global {
    .ant-modal-content,
    .ant-modal-title,
    .ant-modal-close-x {
      background: #0d0d0d;
      border: none;
      color: #ffffff !important;
    }
    .ant-modal-header {
      display: none;
    }

    .ant-modal-close-x {
      width: 24px;
      height: 22.56px;
    }
  }
}
// .modal {
//   // max-width: 1071px !important;
//   // width: 1071px !important;
//   // margin: 0 auto !important;
// }
.sliderModal {
  width: auto;
  :global {
    .ant-modal-content,
    .ant-modal-title,
    .ant-modal-close-x {
      background: rgba(30, 30, 30, 0.9);
      border: none;
      color: #ffffff !important;
    }
    .ant-modal-header {
      display: none;
    }

    .ant-modal-content {
      width: 90vw;
      max-width: 1400px;
      height: 90vh;
    }
    .ant-modal-body {
      height: inherit;
      padding: 0;
    }
    .ant-modal-close-x {
      width: 39px;
      height: 39px;
      span,
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .ant-modal-close {
      margin: 15px 10px 0 0;
    }
  }
}

.carouselContainer {
  width: inherit;
  height: inherit;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  :global {
    .ant-carousel {
      width: 100%;
      height: 90%;
    }
    .slick-slider,
    .slick-list,
    .slick-track {
      height: 100% !important;
    }

    .slick-track {
      display: flex;
      padding: 0 20px;
    }
    .slick-track .slick-slide:last-child {
      display: none;
    }

    .slick-slide {
      transition: all 0.4s ease-in-out;
      transform: translate(-200px, 0px);
    }
    .slick-active {
      padding: 20px;
    }
    .slick-slide div {
      height: 100%;
      width: 100%;
    }

    .slick-center {
      transform: translate(-200px, 0px);
      width: calc(400px + 400px) !important;
      max-width: initial;
      padding: 0;
    }
  }
}

.iconsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 113.69%;
    text-align: center;
    letter-spacing: 0.06em;
    color: #ffffff;
    margin: 0 10px;
  }
  span {
    display: flex;
    align-items: center;
    margin: 0;
    height: 20px;
  }
}

.carouselItem {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

//login & signup modal

.loginSignup {
  :global {
    .ant-modal {
      width: 581px !important;
    }
    .ant-modal-body form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      gap: 10px;
      div {
        display: flex;
        margin: 5px 0;
      }
    }
    .ant-modal-body input {
      height: 60px;
      max-width: 460px;
      border: 1px solid #ff8d4e;
      box-sizing: border-box;
      background: transparent;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.titles,
.arrowWrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  h1 {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 38px;
    text-align: right;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
  }
  span {
    transition: all 0.2s ease-in-out;
  }
}

.signup {
  margin-left: -37% !important;
}
.signupArm {
  margin-left: -35% !important;
}
.signupRu {
  margin-left: -30% !important;
}
.signin {
  margin-left: 40% !important;
}
.signinArm {
  margin-left: 47%;
}
.signinRu {
  margin-left: 54%;
}

//alert and popover
.alert {
  width: 460px;
  align-items: center;
  background: #0d0d0d;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.5s ease-in-out;
  :global {
    .anticon-close {
      color: #ffffff !important;
    }
    .anticon-close:hover {
      color: #ffffff !important;
    }
  }
}
.popover {
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  // top: 110px !important;
  // left: 0 !important;
  :global {
    .ant-popover-inner {
      background: transparent;
    }
    .ant-alert-error {
      border-color: red !important;
    }
    .ant-popover-arrow {
      display: none !important;
    }
  }
}

//apply button
.apply {
  font-family: Futura;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  letter-spacing: 0.06em;
  color: #ff8d4e;
  position: absolute;
  top: 15px;
  right: 5px;
}

@media screen and (max-width: 768px) {
  .popover {
    display: block;
    top: 0 !important;
    padding: 0 !important;
    :global {
      .ant-popover-inner-content {
        padding: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }
  .alert {
    max-width: 80% !important;
  }
}

@media screen and (max-width: 480px) {
  .modal {
    :global {
      .ant-modal-close {
        left: 5px;
      }
      .ant-modal-header {
        display: flex;
        background: #0d0d0d;
        div {
          visibility: hidden;
        }
      }

      .ant-modal-close-x {
        width: 24px;
        height: 22.56px;
      }
    }
  }
}
