.pageContainer {
  width: 100%;
  min-height: 800px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.mainContainer {
  max-width: 1440px;
  width: 100%;
  height: 800px;
  display: flex;
}
.txtContainer {
  width: 43%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  > div {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    width: 80%;
    justify-content: flex-start;
  }
  h2 {
    font-family: Great Vibes;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 45px;
    text-align: right;
    color: #ff8d4e;
    margin: 0;
    flex: 1 1 100%;
    text-align: left;
  }
  h1 {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 64px;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    flex: 1 1 100%;
    text-align: left;
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    color: #ffffff;
    margin: 10px 0;
  }
}

.recsContainer {
  width: 57%;
  height: 100%;
  position: relative;
  background-image: url('../../assets/Images/partners.png');
  background-repeat: no-repeat;
  background-position: top right;
}

.rec1 {
  position: absolute;
  z-index: 3;
  width: 134.9px;
  height: 260px;
  left: 285px;
  top: 12px;
  background: rgba(0, 0, 0, 0.5);
  -webkit-clip-path: polygon(50% 0%, 100% 0%, 50% 100%, 0% 100%);
  clip-path: polygon(50% 0%, 100% 0%, 50% 100%, 0% 100%);
}
.rec2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  width: 253.07px;
  height: 500px;
  left: 30px;
  bottom: 28px;
  background: rgba(0, 0, 0, 0.5);
  -webkit-clip-path: polygon(37% 0%, 100% 0%, 62% 100%, 1% 100%);
  clip-path: polygon(37% 0%, 100% 0%, 62% 100%, 1% 100%);
}
.rec3 {
  position: absolute;
  z-index: 1;
  width: 253.07px;
  height: 497px;
  left: 0px;
  bottom: 5px;
  z-index: -1;
  background: #ff8d4e;
  -webkit-clip-path: polygon(36% 0%, 100% 0%, 64% 100%, 0% 100%);
  clip-path: polygon(36% 0%, 100% 0%, 64% 100%, 0% 100%);
}

//responsive
@media screen and (max-width: 1024px) {
  .txtContainer {
    justify-content: flex-start;
    padding-top: 105px;
  }
  .recsContainer {
    max-height: 771px;
    height: 100%;
    background-size: contain;
  }
  .rec1 {
    width: 102.9px;
    height: 192px;
    left: 190px;
    top: 12px;
  }
  .rec2 {
    height: 367px;
    width: 191.07px;
    bottom: 200px;
    left: -3px;
  }
  .rec3 {
    width: 186.07px;
    height: 360px;
    left: -25px;
    bottom: 177px;
  }
}

@media screen and (max-width: 768px) {
  .txtContainer {
    p {
      font-size: 14px;
      line-height: 16px;
    }
    h1 {
      font-size: 18px;
      line-height: 24px;
    }
    h2 {
      font-size: 16px;
      line-height: 21px;
    }
  }

  .recsContainer {
    max-width: 438.06px;
    width: 100%;
    height: 665.52px;
  }
  .rec1 {
    left: 140px;
    width: 58px;
    height: 142px;
    clip-path: polygon(60% 0%, 100% 0%, 40% 100%, 0% 100%);
  }
  .rec2 {
    bottom: 237px;
    width: 140.07px;
    height: 276px;
    left: 0;
  }
  .rec3 {
    width: 140.07px;
    height: 276px;
    bottom: 223px;
    left: -15px;
  }
}
@media screen and (max-width: 480px) {
  .recsContainer {
    max-width: 280px;
    max-height: 322px;
    height: 100%;
    width: 100%;
    background-image: url('../../assets/Images/Mobile/partners.png');
    background-position-x: left;
    background-size: initial;
  }
  .rec1 {
    left: 95px;
    width: 58px;
    height: 135px;
    clip-path: polygon(50% 0%, 100% 0%, 30% 100%, 0% 100%);
  }
  .rec2 {
    bottom: 0;
    width: 89.07px;
    height: 175px;
    left: 0;
  }
  .rec3 {
    height: 170px;
    width: 99.07px;
    bottom: -10px;
    left: -10px;
    clip-path: polygon(33% 0%, 100% 0%, 67% 100%, 0% 100%);
  }
  .txtContainer {
    padding-top: 65px;
  }
  .txtContainer > div:first-child {
    margin-bottom: 179px;
  }
  .txtContainer > div:not(:first-child) {
    width: 150vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    span,
    div {
      flex-basis: 0;
      flex-grow: 1;
    }
    span {
      text-align: end;
    }
    div {
      p {
        text-align: left;
      }
    }
  }
}
