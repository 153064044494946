.pageContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.userInfoWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  width: 100%;
  max-width: 87%;
  min-height: 300px;
  padding: 20px;
  background-image: url('../../assets/Images/userpage_photo.png');
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  :global {
    .ant-tabs {
      color: #ffffff;
      margin-top: 35px;
      flex: 1 1 100%;
    }

    .ant-tabs-nav-list {
      margin-bottom: 50px;
    }

    .ant-tabs-nav::before {
      border: none !important;
    }

    .ant-tabs-tabpane {
      color: #ffffff !important;
    }

    .ant-tabs-ink-bar,
    .ant-tabs-nav-operations {
      display: none !important;
    }

    .ant-tabs-nav-list > div:first-child {
      min-width: 231.5px;
      height: 70px;
      display: flex;
      align-items: center;
      padding-left: 5px;
      transform: skewX(0);
      margin-left: 0;
      div {
        transform: skewX(0);
      }
      margin-right: -15px;
      -webkit-clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
    }

    .ant-tabs-tab-active {
      background-color: #156dc6 !important;
      color: #ffffff;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #ffffff;
    }

    .ant-tabs-nav-list > div > div {
      transform: skew(35deg);
      max-width: 271px;
      width: 100%;
      height: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      overflow: hidden;
      overflow-wrap: break-word;
    }

    .ant-tabs-tab {
      max-width: 271px;
    }

    .ant-tabs-nav-list > div {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      min-width: 161px;
      height: 70px;
      background-color: #0d0d0d;
      transform: skew(-35deg);
      padding: 0 30px;
      margin: 10px;
      font-family: Futura;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #ffffff;
      span {
        margin: 0;
      }
    }
  }
}

.userInfo {
  max-width: 377px;
  flex: 1 1 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin: 0;
  }

  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #ffffff;
    margin: 0;
  }

  hr {
    border: 1px solid #ff8d4e;
    width: 100%;
  }
}

.userAnnouncements,
.userFavourites {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.settings {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  hr {
    width: 300px;
    border: 1px solid #ff8d4e;
    align-self: flex-start;
    margin: -20px 0 0 0;
  }

  form {
    width: 480px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;

    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1em;
      text-decoration-line: underline;
      color: #ffffff;
      cursor: pointer;
    }

    :global {
      .ant-input:focus {
        border-color: #156dc6 !important;
        box-shadow: none !important;
      }

      .ant-input-number,
      .ant-input {
        width: 100% !important;
        background: transparent;
        border: none;
        border-bottom: 1px solid white;
        text-align: start;
        font-family: Roboto;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.1em;
        color: rgba(255, 255, 255, 0.8);
        outline: none !important;
      }

      .ant-input-number-focused {
        border-bottom: 1px solid #156dc6;
      }

      .ant-input-number-handler-wrap {
        display: none !important;
      }
    }
  }

  //last 3 inputs
  input:nth-child(-n + 4) {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 27px;
    letter-spacing: 0.1em;
    color: #ffffff;
  }

  label {
    align-self: flex-start;
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .userInfo {
    gap: 10px;
    h1 {
      font-size: 18px;
      line-height: 21px;
    }
    h2 {
      font-size: 14px;
    }
  }
  .userInfoWrapper {
    :global {
      .ant-tabs-nav-list > div:first-child {
        clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
      }
      .ant-tabs-nav-list > div,
      .ant-tabs-nav-list > div:first-child {
        height: 50px;
        font-size: 12px;
      }
      .ant-tabs {
        margin-bottom: 25px;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .userInfoWrapper {
    padding: 0 10px;
  }
}
