.headerWpaerr {
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 0px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0.95;
  backdrop-filter: blur(20px);

  .header {
    width: 100%;
    padding: 10px 0;
  }
}

:global {
  .ant-menu-submenu {
    .ant-menu-sub {
      li {
        font-family: "Futura", serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: start;
        letter-spacing: 0.165em;
        text-transform: uppercase;
        border: none !important;
        color: #000;

        img {
          margin-right: 10px;
        }
      }
    }
  }
}

.menuIcon {
  display: none;
}

.innerContainer {
  display: flex;
  align-items: center;
  width: 87%;
  height: inherit;
  margin: 0 auto;

  img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }

  hr {
    height: 20px;
    margin: 0;
  }

  :global {
    .ant-menu-item-selected {
      color: #ffffff !important;
    }

    .ant-menu-item:hover {
      color: #ffffff !important;
      border-bottom: none !important;

      span {
        svg {
          path {
            fill: #156dc6 !important;
          }
        }
      }
    }

    .ant-menu-item {
      margin: 0 10px !important;
      padding: 0 !important;
      border: none !important;
      text-align: center;

      span {
        margin: 0;
      }
    }
  }
}

.logo {
  max-width: 200.96px;
  min-width: 103px;
  max-height: 50px;
  width: 100%;
  cursor: pointer;
}

.navMenu {
  border: none !important;
  color: #ffffff;
  background: transparent;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.findDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 216px;
  width: 20%;
  height: 70px;
  color: #ffffff;
  margin-left: -40px;
  cursor: pointer;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.findText {
  font-family: "Futura";
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  letter-spacing: 0.05em !important;
  text-transform: capitalize;
  color: #ffffff !important;
  margin: 0 !important;
}

.navItem {
  font-family: "Montserrat arm";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;

  a {
    color: #ffffff;
  }

  a:hover {
    color: #156dc6 !important;

    p {
      color: #156dc6;
    }

    svg {
      path {
        fill: #156dc6 !important;
      }
    }

    .IconStar,
    .activeIconStar {
      svg {
        path {
          stroke: #156dc6;
          fill: none !important;
        }
      }
    }
  }
}

.navLink {
  color: #ffffff !important;
  margin: 0 1rem;
}

.activeNav {
  color: #ffffff !important;

  p {
    color: #ffffff !important;
  }
}

.activeIcon {
  svg {
    path {
      fill: #ffffff;
    }
  }
}

.activeIconStar {
  svg {
    path {
      stroke: #ffffff;
      fill: none !important;
    }
  }
}

.ExitIcon {
  svg {
    path {
      fill: #ffffff;
    }
  }
}

//popconfirm
.popConfirm {
  font-family: Futura;

  :global {
    .ant-popover-arrow,
    .ant-popover-inner {
      border-color: #156dc6 !important;
      background: rgba(0, 0, 0, 0.2);
    }

    .ant-popover-message-title {
      color: #ffffff;
    }
    .ant-btn,
    .ant-btn-primary {
      border-color: #156dc6 !important;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  span {
    margin: 0;
  }
}

.span {
  display: inline-block;
  height: inherit;
}
.login_btn {
  background: transparent;
  border: 1px solid rgba(21, 109, 198, 1);
  border-radius: 10px;
  padding: 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 40px;
  }
}
.span:hover {
  color: #156dc6;
}

//badge style
.navItem,
.activeIcon {
  :global {
    .ant-badge-count {
      box-shadow: none !important;

      p {
        margin-left: 1.5px;
      }
    }

    .ant-scroll-number-only {
      font-weight: bold !important;
    }
  }
}

.menuMobile,
.hidden {
  display: none;
}

.profileName {
  color: #ffffff;
  border: 1px solid red;
}
:global {
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: transparent;
    border: 2px solid #00b500;
  }
}
@media (max-width: 991.98px) {
  .innerContainer {
    position: absolute;
    justify-content: flex-start !important;
    display: flex;
    left: 4%;
    z-index: 0;
    width: 7.6rem !important;
  }
  .header {
    height: 50px;
    display: flex;
    justify-content: flex-end;
  }
  .navMenu {
    :global {
      .ant-menu-title-content {
        margin: 0;
      }
    }
  }
  .navItem {
    span {
      margin-left: 5px;
    }
  }
  .navItem:last-child {
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
  .logo {
    display: flex;
    width: 123px;
    height: 45.63px;
    margin: 0;
  }
  .menuMobile {
    display: block;
    width: 100vw;
    height: 100vh;
    position: absolute;
    transition: transform 0.5s ease-in-out;
    transform: translate(0);
  }
  .hidden {
    display: block;
    width: 100vw;
    height: 100vh;
    position: absolute;
    transform: translate(-100vw);
    transition: transform 0.5s ease-in-out;
  }
  .backgroundblack {
    background: #000;
  }
  .innerContainer {
    height: 50px;
    width: 100%;
    justify-content: center;
    padding: 0;
  }

  .menuIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 77.81px;
    width: 100%;
    
    cursor: pointer;
    z-index: 999;

    svg {
      rect {
        transition: transform 0.5s ease-in-out;
      }
    }
  }
  .closeIcon {
    svg {
      height: 22px;

      rect {
        transition: transform 0.5s ease-in-out;
      }

      rect:first-child {
        animation-name: firstRect;
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        @keyframes firstRect {
          from {
            x: 12;
            y: 16;
          }
          25% {
            x: 8;
            y: 14;
          }
          50% {
            x: 0;
            y: 12;
          }
          75% {
            x: -5;
            y: 11;
          }
          to {
            x: -10;
            y: 10;
          }
        }
        fill: red;
        transform: rotate(-45deg);
      }

      rect:nth-child(2) {
        animation-name: secondRect;
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        @keyframes secondRect {
          from {
            x: 17;
            y: 9;
          }
          25% {
            x: 12;
            y: 10;
          }
          50% {
            x: 6;
            y: 10;
          }
          75% {
            x: 0;
            y: 10;
          }
          to {
            x: -5;
            y: 10;
          }
        }
        fill: red;
        transform: rotate(-45deg);
      }

      rect:nth-child(3) {
        animation-name: thirdRect;
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        @keyframes thirdRect {
          from {
            x: 22;
            y: 2;
          }
          25% {
            x: 16;
            y: 0;
          }
          50% {
            x: 10;
            y: 0;
          }
          75% {
            x: 6;
            y: 0;
          }
          to {
            x: -1;
            y: -1;
          }
        }

        transform: rotate(45deg) !important;
        fill: red;
      }
    }
  }
  .findDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    max-width: 50px;
    width: 100%;
    margin-left: -30px;

    span {
      //margin: 0 -25px 0 0;
    }
  }
  .innerContainer > ul {
    display: none;
  }
  .innerContainerMobile {
    margin-top: 70px;

    :global {
      .ant-badge-count {
        top: 5px;
        right: 0 !important;
        border-radius: 50%;
        width: 10px;
        padding: 0;

        span {
          margin: 0;
        }
      }
    }

    ul {
      flex-direction: column;
    }

    .logo {
      display: none;
    }

    hr {
      display: none;
    }
  }
}
//dzac
// @media (min-width: 576px) and (max-width: 767.98px) {
//   .header {
//     display: flex;
//     flex-direction: row-reverse;
//     align-items: center;
//   }
//   .logo {
//     display: flex;
//     width: 7rem;
//     height: 13rem * 0.24;
//   }
//   .innerContainer {
//     display: block;
//   }
//   .navMenu {
//     display: none;
//   }
//   .menuIcon {
//     display: block;
//   }
// }
// //dzac
// @media (max-width: 575.98px) {
//   .header {
//     display: flex;
//     flex-direction: row-reverse;
//     align-items: center;
//   }
//   .logo {
//     display: flex;
//     width: 7rem;
//     height: 13rem * 0.24;
//   }
//   .innerContainer {
//     display: block;
//   }
//   .navMenu {
//     display: none;
//   }
//   .menuIcon {
//     display: block;
//   }
// }
@media (max-width: 575.98px) {
  .innerContainer {
    top: 0;
  }
  .navLink {
    font-size: 1rem !important;
  }
}
