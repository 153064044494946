.resultsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .currenciesContainer {
    clear: both;
    width: 100%;
    margin-bottom: 40px;

    .currencySeparator,
    .currencyContainer {
      display: inline-block;
      vertical-align: middle;
      padding-right: 15px;

      .currencyContainerValue {
        color: #ff8d4e;
      }
    }

    .currencySeparator {
      line-height: 10px;

      @media (max-width: 768px) {
        max-width: 8px;
      }
    }

    .currencyContainer {
      @media (max-width: 768px) {
        padding: 0;
      }
    }

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
    }
  }

  .notesContainer {
    text-align: center;
    color: #ff8d4e;
    clear: both;
    width: 100%;
    font-weight: bold;
    padding: 5px;
    word-wrap: break-word;
  }
}


.noticeText {
  margin: auto;
  width: 100%;
  text-align: left;
  max-width: 1042px;
}

.noticeTextRedTitle {
  color: #b82727;
  font-size: 24px;
  line-height: 37px;
  margin: auto;
  text-align: center;
  width: 100%;
  max-width: 1042px;
}

.noticeTextOrange {
  color: #ff8d4e;
  width: 100%;
  text-align: left;
  max-width: 1042px;
  margin: auto;
}

.resultsTable {
  max-width: 1042px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;

  > span {
    flex: 1 1 50%;
    max-width: 50%;
    height: 40px;
    border: 1px solid grey;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 !important;
    padding: 5px 0;
    font-family: 'Futura';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
  }

  .number {
    color: #ff8d4e;
    min-width: 21%;
    text-align: right;
  }

  .separator {
    min-width: 1%;
  }

  .labelName {
    align-items: flex-start;
    text-align: left;
    width: 67%;
    margin-left: 0;
  }
}

.resultsTableDesktop {
  display: flex;
}

.resultsTableMobile {
  display: none;
}

.total {
  width: 100%;
  max-width: 1042px;
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;

  p {
    font-family: 'Futura';
    font-style: normal;
    font-weight: 500;
    margin: 0 0;
  }

  p:first-child {
    margin-top: 20px;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.1em;
    color: #ffffff;
  }

  p:nth-child(2) {
    font-size: 48px;
    line-height: 64px;
    text-align: right;
    letter-spacing: 0.06em;
    color: #ff8d4e;
  }
}

@media screen and (max-width: 680px) {
  .resultsTable {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;

    > span {
      display: flex;
      flex-direction: row;
      flex: initial;
      flex-wrap: nowrap;
      padding: 10px;
      height: auto;
      align-items: center;
      width: 355px;
      flex: initial;
      max-width: none;
      background: #1e1e1e;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      font-size: 0.75rem;
    }

    .labelName {
      text-align: left;
      margin-left: 0;
      width: auto;
    }
  }
  .total {
    max-width: none;
    flex: none;
    width: 355px;

    p:nth-child(2) {
      font-size: 24px;
      line-height: 37px;
    }
  }

  .resultsTableDesktop {
    display: none;
  }
  .resultsTableMobile {
    display: flex;
  }
}
