.filter-form {
  .ant-input,
    .ant-select {
      height: 56px;
      background: #171717;
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      color: #ffffff;
      text-align: left;
      padding-left: 1rem;
      width: 100%;
      border-radius: 10px;
    }
    .ant-select-selector {
      height: 100% !important;
      color: #ffffff;
      display: flex;
      align-items: center;
      background-color: transparent !important;
      input {
        height: 100% !important;
      }
    }
    .ant-select-arrow {
      color: #ffffff;
    }
  padding: 20px;
  color: #fff;
  h2 {
    margin-bottom: 20px;
    font-size: 18px;
    color: #fff;
    text-align: left;
  }
  .select-block {
    width: 100%;
  }
  .form-group {
    margin-bottom: 15px;
    width: 24rem;
    text-align: left;
    display: block;
    label {
      display: flex;
      font-family: "Futura";
      font-style: normal;
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 12px;
      letter-spacing: 0.1em;
      color: #ffffff;
      margin: 10px 0;
    }
    select,
    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #555;
      border-radius: 5px;
      background-color: #181818;
      color: #fff;
      border-radius: 10px;
      &:focus {
        border: none !important;
        outline: none !important;
      }
    }

    .price-range,
    .engine-inputs {
      display: flex;
      justify-content: space-between;

      input {
        width: 48%;
      }
    }
  }

  button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: #181818;
    color: #dddddd;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.03);
    }
  }
  .filterBtn {
    border: 2px solid #434343;
    background-color: rgba(255, 255, 255, 0.03);
    border-image-slice: 1;
    margin: 5% 0;
    padding: 16px;
  }
}
.dropDown {
  background: #0d0d0d;
  .ant-select-item {
    background: #0d0d0d;
    color: #ffffff;
  }
  .ant-select-item-option-active {
    background: #156dc6;
    display: flex !important;
  }
  .ant-select-item-option-content {
    display: flex;
  }
}
@media (max-width: 768px) {
  .filter-form {
    padding: 15px;

    h2 {
      font-size: 16px;
    }

    .form-group {
      width: 100%;

      select,
      input {
        padding: 8px;
      }
    }

    button {
      padding: 8px;
      font-size: 12px;
    }
  }
}

@media (max-width: 480px) {
  .filter-form {
    padding: 10px;

    h2 {
      font-size: 14px;
    }

    .form-group {
      width: 100%;

      select,
      input {
        padding: 6px;
      }
    }

    button {
      padding: 6px;
      font-size: 12px;
    }

    .price-range,
    .engine-inputs {
      display: block;

      input {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}
