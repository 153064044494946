.mainContainer {
  margin-top: 70px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 70px;
}

.mainContainer > h1 {
  font-family: Futura;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-align: start;
  margin-bottom: 25px;
}

.uploadContainer {
  background-color: #0d0d0d;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 70px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px;

  div {
    width: 250px;
    height: 250px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    > span {
      position: absolute;
      margin: 0;
      right: 5px;
      top: 5px;
      cursor: pointer;
    }
  }
}

.uploadContainer > h1 {
  font-family: Futura;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #ffffff;
  flex: 0 0 100%;
  text-align: start;
}

.formContainer {
  background-color: #0d0d0d;
  margin-bottom: 70px;
  padding: 20px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textContainer > textarea {
  max-width: 1169px;
  width: 100%;
  height: 238px;
  background: #0d0d0d;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  margin-bottom: 50px;
  padding: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.8);
  resize: none;
}

.phoneInput,
.wrongPhone {
  width: 369px;
  height: 60px;
  margin-bottom: 50px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  :global {
    .ant-input-wrapper {
      height: inherit;
    }
    .ant-input,
    .ant-input-group-addon {
      height: inherit !important;
      background: #0d0d0d !important;
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
    }
    .ant-input-group-addon {
      color: #ffffff;
      width: 96px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      text-transform: capitalize;
    }
    .ant-input {
      margin-left: 10px;
      color: #ffffff;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      text-transform: capitalize;
    }
  }
}
.wrongPhone {
  :global {
    .ant-input,
    .ant-input-group-addon {
      border: 1px solid red !important;
    }
  }
}
.popover {
  :global {
    .ant-popover-inner {
      background: #0d0d0d !important;
      border: 1px solid red;
    }
    .ant-popover-inner-content {
      background: #0d0d0d !important;
    }
    .ant-popover-arrow {
      border-right-color: red !important;
      border-bottom-color: red !important;
    }
  }
}
.alert {
  background: transparent;
  color: #ffffff;
  width: 300px !important;
  transition: all 0.5s ease-in;
  border: none;
  align-items: center;
}

//image div

.deleted {
  background: #0d0d0d;
}
.undoWrapper {
  position: absolute !important;
  background: rgba(13, 13, 13, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  align-items: center;
  h3 {
    color: #ffffff !important;
    margin: 0;
    cursor: default;
  }
}

.notification {
  background: #0d0d0d;
  color: #ffffff;
  :global {
    .ant-notification-notice-close {
      color: #ffffff;
    }
  }
}
.asterisk {
  color: red;
  font-size: 22px;
}
//responsive
@media screen and (max-width: 480px) {
  .uploadContainer {
    flex-direction: column-reverse;
    h1 {
      display: none;
    }
    section {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      width: 100%;
      gap: 10px;
    }
    div {
      width: 100px;
      height: 100px;
      flex: 0 0 auto;
    }
  }
}
