$primary: #4a4a4a;
$secondary: #ffffff;
$background_color: #0f0f0f;
.home-service {
  color: $secondary;
  border-bottom: 1px solid $primary;
  padding: 5% 5% 5%;
  width: 100%;
  position: relative;
  font-family: "Montserrat arm", sans-serif;
  background-color: $background_color;
  .title {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 40px;
    font-weight: 700;
  }
  .services-grid {
    display: grid;
    grid-template-columns: 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr;
    grid-template-rows: 394px;
    gap: 3%;
    grid-template-areas:
      "item5 item5 item5 item4 item4 item4 item4 item3"
      "item2 item2 item2 item2 item2 item1 item1 item3";
    .service-item {
      width: 100%;
      height: 100%;
      display: grid;
      .service-image {
        width: 100%;
        border-radius: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        border: 2px solid #7b7b7b;
        background-color: #1f1f1f;
        padding: 10px;
        background-clip: content-box;
      }

      .service-info {
        padding: 15px 10px;

        .description {
          font-size: 1.3em;
          margin: 0;
          font-weight: 400;
        }

        .price {
          padding: 4px 0;
          font-size: 1.8em;
          font-weight: bold;
          margin: 0;
          border-radius: 5px;
        }
      }
    }
    .service-item:nth-child(5) {
      grid-area: item5;
      .service-image {
        height: 296px;
        background-image: url(../../../assets/Images/gridImage_1.webp);
      }
    }
    .service-item:nth-child(4) {
      grid-area: item4;
      .service-image {
        height: 296px;
        background-image: url(../../../assets/Images/gridImage_2.webp);
        background-position: center;
      }
    }

    .service-item:nth-child(3) {
      grid-area: item3;
      .service-image {
        height: 733px;
        background-image: url(../../../assets/Images/gridImage_3.webp);
        background-position: center;
      }
    }
    .service-item:nth-child(2) {
      grid-area: item2;
      .service-image {
        background-image: url(../../../assets/Images/gridImage_4.webp);
        background-size: cover;
        background-position: center;
        height: 296px;
      }
    }
    .service-item:nth-child(1) {
      grid-area: item1;
      .service-image {
        height: 296px;
        background-image: url(../../../assets/Images/gridImage_5.png);
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .home-service .services-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 0.5fr 0.5fr;
    grid-template-areas:
      "item5 item4"
      "item2 item1"
      "item3 item3"
      "item3 item3";
  }
  .home-service {
    padding-bottom: 20%;
  }
  .home-service .services-grid .service-item {
    display: block;
  }
  .home-service .services-grid .service-item:nth-child(3) .service-image {
    height: 100% !important;
  }
}

@media (max-width: 767.98px) {
  .home-service .services-grid {
    display: block !important;
  }
  .home-service .services-grid .service-item:nth-child(3) .service-image {
    height: 296px !important;
  }
}
