.secondSection {
  margin: 5rem 0px;
  width: 100%;
}

.titles,
.arrowWrapper {
  text-align: left;
}
.titles h1:nth-child(2),
.arrowWrapper {
  display: none;
}

.titles > h1 {
  font-family: Montserrat arm;
  font-size: 2rem;
  font-weight: 700;
  line-height: 39.01px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.arrowWrapper > span {
  transition: all 0.2s ease-in-out;
}

.carsWrapper {
  margin-top: 5rem;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .titles h1:nth-child(2),
  .arrowWrapper {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .carsWrapper {
    padding-inline: 20px;
  }
}
