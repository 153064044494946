.inputsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: flex-start;
  width: 100%;
  :global {
    .ant-input,
    .ant-select {
      height: 56px;
      background: #171717;
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      color: #ffffff;
      text-align: left;
      padding-left: 1rem;
      width: 100%;
      border-radius: 10px;
    }
    .ant-select-selector {
      height: 100% !important;
      color: #ffffff;
      display: flex;
      align-items: center;
      background-color: transparent !important;
      input {
        height: 100% !important;
      }
    }
    .ant-select-arrow {
      color: #ffffff;
    }
  }
  .lineStyle {
    width: 1px;
    border: 1px solid grey;
    height: 320px;
  }
  hr {
    height: inherit;
    margin: 0 20px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.noticeText {
  margin: auto;
  max-width: 100%;
  text-align: justify;
  margin-top: 25px;
}
@media screen and (max-width: 1199.98px) {
  .inputsContainer {
    flex-direction: column;
  }
  .lineStyle {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .inputsContainer {
    flex-direction: column;

    hr {
      display: none;
    }
    .lineStyle {
      display: none;
    }

    :global {
      .ant-input,
      .ant-select {
        width: 343px;
        height: 40px;
        background: #1e1e1e;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
      }
      .ant-select-arrow {
        right: -40%;
      }
    }
  }
  .noticeText {
    max-width: 90%;
  }
}
