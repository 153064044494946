$primary: #4a4a4a;
$white: #ffffff;
$header-color: rgba(21, 109, 198, 1);

.home-about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 2.8rem 5%;
  background-color: #0f0f0f;
  border-bottom: 1px solid $primary;
  font-family: "Montserrat arm", sans-serif;

  .content {
    text-align: center;
    &-header {
      color: $header-color;
      font-family: Montserrat arm;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      opacity: 0.8;
    }
    &-header-p {
      color: #dbdbdb;
      opacity: 0.7;
      padding-bottom: 1rem;
    }
    &-why-us {
      font-family: Montserrat arm;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      color: white;
    }
  }

  .stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 43%;
      padding-left: 5%;
    }
    .stat-item {
      text-align: center;
      &-p {
        font-family: Montserrat arm;
        font-size: 60px;
        font-weight: 600;
        letter-spacing: -0.02em;
        text-align: center;
        color: $header-color;
        margin-bottom: 0;
      }
      &-span {
        font-family: Montserrat arm;
        font-size: 20px;
        text-align: center;
        color: $white;
      }
    }
  }

  .home-about-us-img {
    width: 50rem;
    height: 25rem;
    background-image: url("../../../assets/Images/aboutusBackground.png");
    background-size: cover;
    background-position: center;
  }

  @media (min-width: 1200px) and (max-width: 1919.98px) {
    .content-why-us {
      font-size: 30px;
    }
    .stat-item .stat-item-p {
      font-size: 2.8rem;
    }
    .stat-item .stat-item-span {
      font-size: 1rem;
    }
    .home-about-us-img {
      height: 22rem;
      width: 40rem;
    }
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    .content-why-us {
      font-size: 28px;
    }
    .stats-container {
      width: 60%;
      padding-right: 3%;
    }
    .stat-item .stat-item-p {
      font-size: 3rem;
    }
    .stat-item .stat-item-span {
      font-size: 1rem;
    }
    .home-about-us-img {
      height: 20rem;
      width: 30rem;
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    .content-why-us {
      font-size: 26px;
    }
    .stats {
      flex-direction: column;
    }
    .stats-container {
      width: 90%;
      margin-bottom: 2rem;
    }
    .stat-item .stat-item-p {
      font-size: 2rem;
    }
    .stat-item .stat-item-span {
      font-size: 1rem;
    }
    .home-about-us-img {
      width: 90%;
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    .content-why-us {
      font-size: 24px;
    }
    .stats {
      flex-direction: column;
    }
    .stat-item-p {
      font-size: 35px;
    }
    .stat-item-span {
      font-size: 12px;
    }
    .home-about-us-img {
      height: 16rem;
    }
  }

  @media (max-width: 575.98px) {
    .content-why-us {
      font-size: 22px;
    }
    .stats {
      flex-direction: column;
    }
    .stats-container {
      width: 90%;
      margin: 5% 0;
    }

    .stats .stat-item-p {
      font-size: 30px;
    }
    .stats .stat-item-span {
      font-size: 12px;
    }
    .home-about-us-img {
      height: 14rem;
      width: 90%;
    }
  }
}
