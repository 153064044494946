.pageContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.label {
  font-family: 'Futura';
  background: orange;
  padding: 0 20px;
  margin-bottom: 5px;
  margin-top: -20px;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .pageContainer {
    margin-top: 70px;
  }
}
