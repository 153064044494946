.floatLabel {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  //   margin-bottom: 12px;
}

.label {
  //   font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 50px;
  top: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1em;
  color: rgba(255, 255, 255, 0.8);
  transition: 0.2s ease all;
}

.labelFloat {
  top: -12px;
  left: 45px;
  background: #0d0d0d;
}
