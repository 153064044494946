.pageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  //   padding-top: 70px;
  .iframeContainer {
    margin-top: 70px;
    padding: 10px 20px;
    width: 100%;
    height: calc(100vh - 70px);
    > iframe {
      width: 100%;
      height: 100%;
    }
  }
}
