.pageWrapper {
  max-width: 1440px;
  width: 100%;
  margin-top: 70px;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userInfo {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  hr {
    border: 1px solid #ff8d4e;
    max-width: 377px;
    width: 100%;
    margin: 10px 0 10px 0;
  }
  h1 {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin: 0;
  }
  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #ffffff;
    margin: 0;
  }
}
.carsContainer {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.carsContainer > div {
  flex: 1 1 278;
}

//pagination
.pagination {
  margin-bottom: 10px;
  :global {
    .ant-pagination-prev {
      margin: 0 !important;
    }
  }
}

.pagination > li {
  background: transparent !important;
  border: none;
  width: 15px;
  height: 32px;
}

.pagination > li > a {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  letter-spacing: 0.06em;
  color: #ffffff;
}

.pagination > li > button {
  background: transparent !important;
  border: none !important;
  color: #ffffff !important;
  font-size: 24px !important;
}
.pagination > li > button:disabled {
  color: #424242 !important;
}

.pagination {
  :global {
    .ant-pagination-item-active > a {
      transition: all 0.2s;
      color: #156dc6 !important;
      font-size: 28.5px !important;
    }
    .ant-pagination-item:hover > a {
      color: #156dc6 !important;
    }
  }
}
