.divContainer {
  max-width: 1440px;
  width: 100%;
  min-height: 529px;
  display: flex;
}

.txtContainer,
.imgContainer {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imgContainer {
  background-image: url('../../assets//Images/policeman.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
