$primary: #4a4a4a;
$secondary: #ffffff;
.cardContainer {
  width: calc(100% / 4 - 8px);
  flex: 0 0 auto;
  position: relative;
}

.innerContainer {
  height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  #annId {
    position: absolute;
    top: calc(50% - 25px);
    left: 50%;
    transform: translate(-50%, 0);
    button {
      max-width: 204px;
    }
  }
}

.imgContainer {
  position: relative;
  width: 100%;
  height: 17rem;
  border: 2px solid #7b7b7b;
  background-color: rgba(0, 0, 0, 0.01);
  border-image-slice: 1;
  border-radius: 10px;
  img {
    width: 100%;
    height: 100%;
  }
}

.imgContainer button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.info {
  width: 100%;
  margin-top: 5%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.brand {
  line-height: 18px;
  text-align: left;
  letter-spacing: 0.06em;
  color: $secondary;
  font-family: Montserrat arm;
  font-size: 20px;
  font-weight: 600;
}

.model {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.06em;
  color: $secondary;
}

.price {
  text-align: right;
  color: $secondary !important;
  font-family: Montserrat arm;
  font-size: 18px;
  font-weight: 400;
}
.year {
  color: $secondary;
}

.cardContainer span {
  font-family: Montserrat arm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  letter-spacing: 0.06em;
  text-decoration-line: underline;
  color: $secondary;
  float: right;
  cursor: pointer;
  margin: 10px 0;
}

// Announcement card

.iconsWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 10px;
}

//favourites

.cardContainer > button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

//status label
.shopStatus {
  transform: rotate(-40deg);
  position: absolute;
  width: 100%;
  top: 50px;
  left: -65px;
  display: flex;
  justify-content: center;
  z-index: 1;
}

//popconfirm
.popConfirm {
  font-family: Futura;
  :global {
    .ant-popover-arrow,
    .ant-popover-inner {
      background: #0d0d0d;
      border-color: #156dc6 !important;
    }
    .ant-popover-message-title {
      color: $secondary;
    }

    .ant-btn-primary {
      background: #156dc6 !important;
      border-color: #156dc6 !important;
    }
  }
  span {
    margin: 0;
  }
}
.span {
  display: inline-block;
  height: inherit;
}
.span:hover {
  color: #156dc6;
}

.notification {
  background: #0d0d0d;
  color: $secondary;
  :global {
    .ant-notification-notice-close {
      color: $secondary;
    }
  }
}

//responsive

@media screen and (max-width: 768px) {
  .cardContainer {
    width: calc(100% / 2 - 8px);
  }

  //list view
  .cardContainerList {
    width: 100%;
  }
  .innerContainerList {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 101px;
    align-items: center;
    justify-content: center;
  }

  .imgContainerList {
    clip-path: polygon(0 0%, 100% 0, 70% 100%, 0% 100%);
    width: 201px;
    height: 101px;
    margin-right: -25px;
    img {
      height: inherit;
      object-fit: cover;
    }
  }
  .infoList {
    background: #0d0d0d;
    width: 201px;
    height: 101px;
    clip-path: polygon(30% 0, 100% 0%, 100% 100%, 0% 100%);
    margin: 0;
    margin-left: -25px;
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    padding-right: 15px;
  }
  .shopStatus {
    top: 30px;
    left: -30px;
  }
  .innerContainer {
    width: 100%;
    height: auto;
    justify-content: space-between;
    a {
      button {
        width: 120px;
        height: 40px;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .imgContainer {
    height: 194px;
  }
  .info {
    width: inherit;
    height: 137px;
    margin-top: 0;

    p {
      margin-bottom: 10px;
    }
    h1:last-child {
      margin-bottom: 10px;
    }
  }

  .brand {
    font-size: 14px;
    line-height: 19px;
    margin: 0;
  }
  .model {
    font-size: 12px;
    line-height: 14px;
    margin: 0;
  }
  .price {
    font-size: 18px;
    line-height: 24px;
    margin: 0;
  }
  .year {
    font-size: 12px;
    line-height: 24px;
    margin: 0;
  }
  .iconsWrapper {
    span {
      line-height: 10px;
      svg {
        height: 20px;
      }
    }
  }
  .popConfirm {
    max-width: 350px;
  }
}

@media screen and (max-width: 480px) {
  .cardContainer {
    width: 100%;
  }
  .info {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}
