.hot-offers {
  padding: 20px;
  color: #fff;

  h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #fff;
    text-align: left;
  }

  .hot-offer-items {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  }

  .hot-offer-item {
    overflow: hidden;
    position: relative;
    flex: 0 0 32%;
    margin-bottom: 20px;
    
    .hot-offer-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 8px;
      border: 2px solid #7b7b7b;
      background-color: rgba(255, 255, 255, 0.03);
      padding: 5px;
      border-image-slice: 1;
      position: relative;
      overflow: hidden;
      min-height: 17rem;
    }
    .img {
      width: 100%;
      max-width: 100%;
      border-radius: 8px;
    }
    .img:hover {
      opacity: .5;
    }
    .hoverBtn {
      position: absolute;
    }
    .hot-offer-info {
      padding: 10px;
      width: 100%;
      h3 {
        font-size: 16px;
        margin: 10px 0;
        color: #fff;
        text-align: left;
      }

      p {
        font-size: 14px;
        margin: 0;
        text-align: left;
      }
    }

    .hot-badge {
      position: absolute;
      top: 0;
      right: 3%;
      background: #cd000099;

      color: #fff;
      padding: 5px 10px;
      font-size: 12px;
      border-bottom-left-radius: 5px;

      @media screen and (max-width: 480px) {
        right: 0;
      }
    }
  }
  .notification {
    background: #0d0d0d;
    color: #ffffff;
    :global {
      .ant-notification-notice-close {
        color: #ffffff;
      }
    }
  }
  .iconsWrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
  }
  .popConfirm {
    font-family: Futura;
    :global {
      .ant-popover-arrow,
      .ant-popover-inner {
        background: #0d0d0d;
        border-color: #156dc6 !important;
      }
      .ant-popover-message-title {
        color: #ffffff;
      }

      .ant-btn-primary {
        background: #156dc6 !important;
        border-color: #156dc6 !important;
      }
    }
    span {
      margin: 0;
    }
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media screen and (max-width: 1919.98px) {
  .filter-form .form-group {
  }
  .hot-offers {
    padding: 30px 0 30px 30px;
  }

  h2 {
    font-size: 2rem;
  }

  .hot-offers .hot-offer-items {
    gap: 18px;
  }

  .hot-offers .hot-offer-item {
    flex: 1 1 31%;
  }

  .hot-offers .hot-offer-item .hot-offer-image {
    width: 100%; // 15rem;
    min-height: 14rem;
  }

  .hot-offer-info h3 {
    font-size: 20px;
  }

  .hot-offer-info p {
    font-size: 18px;
  }

  .hot-badge {
    font-size: 16px;
    padding: 8px 14px;
  }
}

/* Large devices (desktops, 992px and up) */
@media screen and (max-width: 1199.98px) {
  .hot-offers {
    padding: 25px;
  }

  h2 {
    font-size: 1.75rem;
  }

  .hot-offers .hot-offer-items {
    gap: 18px;
  }

  .hot-offers .hot-offer-item {
    flex: 1 1 31%;
  }

  .hot-offer-image {
    width: 23rem;
    min-height: 17rem;
  }

  .hot-offer-info h3 {
    font-size: 18px;
  }

  .hot-offer-info p {
    font-size: 16px;
  }

  .hot-badge {
    font-size: 14px;
    padding: 6px 12px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media screen and (max-width: 991.98px) {
  .hot-offers {
    padding: 20px;
  }

  h2 {
    font-size: 1.5rem;
  }

  .hot-offer-image {
    width: 20rem;
    min-height: 15rem;
  }

  .hot-offer-info h3 {
    font-size: 16px;
  }

  .hot-offer-info p {
    font-size: 14px;
  }

  .hot-badge {
    font-size: 12px;
    padding: 5px 10px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 768px) {
  .hot-offers {
    padding: 15px;
  }

  h2 {
    font-size: 1.25rem;
  }

  .hot-offers .hot-offer-item {
    flex: 1 1 100%;
  }

  .hot-offers .hot-offer-item .hot-offer-image {
    width: 100%;
    min-height: 17rem;
  }

  .hot-offer-info h3 {
    font-size: 14px;
  }

  .hot-offer-info p {
    font-size: 12px;
  }

  .hot-badge {
    font-size: 10px;
    padding: 3px 7px;
  }
}

@media screen and (max-width: 575.98px) {
  .hot-offers {
    padding: 10px;
  }

  h2 {
    font-size: 1rem;
  }

  .hot-offers .hot-offer-item {
    flex: 1 1 100%;
  }

  .hot-offer-image {
    width: 100%;
    height: auto;
  }

  .hot-offer-info h3 {
    font-size: 12px;
  }

  .hot-offer-info p {
    font-size: 10px;
  }

  .hot-badge {
    font-size: 8px;
    padding: 2px 5px;
  }
}
