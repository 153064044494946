$Primiary: #ffff;
* {
  border: none;
}
.pageContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.firstSection {
  // max-width: 1171px;
  width: 100%;
  // margin: 0 5%;
  margin-top: 2%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.firstSection > div {
  flex: 1; // change for smaller screens
}

//Carousel Block

.carouselContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-width: 50%;
}

.icons {
  margin-top: -15px;
}

.bigPic {
  // height: 445.5px;
  height: 40rem;
  width: 100%;
  margin-bottom: 3%;
  cursor: zoom-in;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.carouselContainer {
  :global {
    .ant-carousel {
      width: 100%;
      height: 100%;
    }

    .slick-slider,
    .slick-list,
    .slick-track {
      height: inherit !important;
    }

    .slick-slide div {
      height: inherit;
    }
  }
}

.carouselItem {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 40rem !important;
}

.smallPicsWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
}

.smallPicsWrapperItem {
  width: calc(100% / 4 - 6px);
  height: 10rem;
  box-sizing: border-box;
  vertical-align: top;
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
  cursor: zoom-in;
  outline: none;
}

.third {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.7;
  pointer-events: none;
}

.third > h1 {
  font-family: "Montserrat arm";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 32px;
  letter-spacing: 0.06em;
  color: #ffffff;
  z-index: 1;
}

//info block

.carInfoContainer h1 {
  font-family: "Montserrat arm";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  text-align: left;
  letter-spacing: 0.06em;
  color: #ffffff;
}

.title {
  width: 100%;
  margin-bottom: 20px;
}

.title h1 {
  font-size: 2.6rem;
  font-family: Montserrat arm;
  font-weight: 700;
}

.title hr {
  flex: 1 1 100%;
}

.title p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.06em;
  color: #ffffff;
}

.year {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  letter-spacing: 0.06em;
  color: $Primiary;
}

.yearMobile {
  display: none;
}

.info {
  padding: 0 20px;
  width: 100%;
  text-align: left;
  border: 1px solid #3a3a3a;
  padding: 2%;
  border-radius: 10px;
}

.info p,
.price p {
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.06em;
  color: #ffffff;
  text-transform: capitalize;
  margin: 0;
}
.info div {
  border-bottom: 1px solid #3a3a3a;
  display: flex;
  justify-content: space-between;
  padding: 0 1%;
}

.info h2 {
  font-family: Montserrat arm;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  margin-bottom: 0;
  padding: 1% 0;
}
.info span {
  font-family: Montserrat arm;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: rgba(219, 219, 219, 1);
  margin-right: 5px;
  min-width: 220px;
  padding: 1% 0;
}
.infoColumn {
  align-items: flex-start;
  justify-content: space-between;
  word-break: break-word;
  word-wrap: break-word;
}

.price {
  text-align: left;
  margin-top: 20px;
}

.priceMobile {
  display: none;
}

.price h1 {
  color: rgba(255, 255, 255, 1);
  font-family: Montserrat arm;
  font-size: 1.25rem;
  font-weight: 400;
}

.btnsWrapper {
  display: flex;
  background: transparent !important;
  justify-content: flex-end;
  gap: 15px;
  margin-top: -55px;
  margin-right: 15px;
}

.labels {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 20px;

  p {
    font-family: "Montserrat arm";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.06em;
    color: #ffffff;
    text-transform: capitalize;
    margin: 0;
    min-width: 14rem;
    padding: 0 0.5rem;
    height: 3.5rem;
    margin-right: 2%;
    border-radius: 10px;
    border: 2px solid #00b500;
    background: rgba(0, 181, 0, 0.13);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.labelsMobile {
  display: none;
}

//description

.description {
  flex: 0 1 50% !important;
  // padding: 20px 0;
  font-family: Montserrat arm;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.3px;
}

.dTitle {
  display: flex;
  justify-content: space-between;
}

.dTitle > h1 {
  font-family: "Montserrat arm";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  letter-spacing: 0.06em;
  color: #ffffff;
}

.dTitle > .phone {
  font-family: "Montserrat arm";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  letter-spacing: 0.06em;
  color: #ff8d4e;
}

.description p {
  text-align: left;
}

.description span {
  font-family: "Montserrat arm";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #156dc6;
  cursor: pointer;
}
.lineStyle {
  border-bottom: 1px solid #272727;
  margin: 4% 0;
  height: 1px;
}
.carInfoContainer {
  width: 47%;
  display: block !important;
}
//media queries

@media only screen and (max-width: 1199.98px) {
  .firstSection {
    justify-content: center;
  }
  .firstSection > div {
    // flex: 1 1 100%;
  }
  .carouselContainer,
  .carInfoContainer,
  .description {
    max-width: 100%;
  }

  .bigPic {
    clip-path: none;
  }
  .smallPicsWrapper {
    justify-content: center;
    gap: 1rem;
  }
  .icons {
    z-index: 1;
    margin: 0;
    position: absolute;
    height: 25px;
  }
  .carouselContainer {
    position: relative;

    :nth-child(2) {
      left: 5px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      display: flex;
      align-items: center;
    }

    :nth-child(3) {
      right: 5px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      display: flex;
      align-items: center;
    }
  }
  .description {
    flex: 1 1 100% !important;
  }
}

@media only screen and (max-width: 480px) {
  * {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .carouselContainer,
  .carInfoContainer,
  .description {
    max-width: 100%;
  }
  .bigPic {
    width: 100%;
    max-height: 348px;
  }

  .title {
    margin-bottom: 10px;
    h1 {
      font-size: 24px;
      padding-left: 1rem;
    }
    p {
      flex: 1 1 50%;
      text-align: start;
    }
  }
  .info {
    flex-direction: column;
    padding: 0 10px 40px 10px;
    align-items: flex-start;
    display: flex;
    > div {
      flex-direction: row;
      padding: 0;
      width: 100%;
    }
    hr {
      display: none;
    }
  }
  .infoColumn {
    max-width: 100%;
    gap: 5px;

    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 12px;
        line-height: 16px;
      }

      h2 {
        margin: 0;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
  .description {
    flex: 1 1 100% !important;
  }
  .price {
    display: none;
  }
  .priceMobile {
    display: inline-block;
    font-family: "Montserrat arm";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 32px;
    text-align: left;
    padding-left: 1rem;
    letter-spacing: 0.06em;
    color: $Primiary;
  }
  .year {
    display: none;
  }
  .yearMobile {
    display: inline-block;
    font-family: "Montserrat arm";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #156dc6;
  }
  .labels {
    display: block;
  }
  // .labelsMobile {
  //   width: 100%;
  //   display: flex;
  //   flex-wrap: wrap;
  //   padding: 0 20px 0 20px;
  //   margin: 0 0 15px 0;
  //   gap: 5px;

  //   p {
  //     font-family: "Montserrat arm";
  //     font-style: normal;
  //     font-weight: 500;
  //     font-size: 14px;
  //     line-height: 19px;
  //     letter-spacing: 0.06em;
  //     color: #ffffff;
  //     text-transform: capitalize;
  //     margin: 0;
  //     padding: 0 5px 0 5px;
  //     background: #156dc6;
  //   }
  // }
  .btnsWrapper {
    margin-top: -27px;
  }
  .smallPicsWrapperItem {
    flex: 1 1 30%;
    width: unset;
    max-width: unset;
    margin: 0;
  }
  .carouselItem {
    height: 100% !important;
  }
}
