$primary: #4a4a4a;
$secondary: #ffffff;
$background_color: #0f0f0f;
.home-logistic {
  color: $secondary;
  border-bottom: 1px solid $primary;
  padding: 5% 5% 5%;
  width: 100%;
  position: relative;
  font-family: "Montserrat arm", sans-serif;
  background-color: $background_color;

  .title {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 40px;
    font-weight: 700;
  }
  .services-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .logistic-item {
      flex: 1 1 30%;

      .service-image {
        height: 296px;
        border: 1px solid;
        border-radius: 10px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 10px;
        background-clip: content-box;
      }
      .service-info {
        padding: 15px 10px;

        .description {
          font-size: 1.3em;
          margin: 0;
          font-weight: 400;
        }

        .price {
          padding: 4px 0;
          font-size: 1.8em;
          font-weight: bold;
          margin: 0;
          border-radius: 5px;
        }
      }
    }
    .logistic-item:nth-child(3) {
      .service-image {
        background-image: url(../../../assets/Images/international_transport.jpg);
      }
    }
    .logistic-item:nth-child(2) {
      .service-image {
        background-image: url(../../../assets/Images/local_transportation.jpg);
      }
    }
    .logistic-item:nth-child(1) {
      .service-image {
        background-image: url(../../../assets/Images/auction.webp);
        background-position: center;
        background-size: cover;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .home-logistic {
    padding-bottom: 20%;
  }
  .home-logistic .services-grid .logistic-item .service-image {
    height: 200px;
  }
}

@media screen and (max-width: 768px) {
  .home-logistic .services-grid {
    flex-direction: column;
  }
  .home-logistic .services-grid .logistic-item {
    flex: auto;
    width: 100%;
  }
  .home-logistic .services-grid .logistic-item .service-image {
    height: 296px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .home-logistic {
    padding-bottom: 20%;
  }
  .home-logistic .services-grid .logistic-item {
    display: block;
  }
}
