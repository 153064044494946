.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background: #0f0f0f;
  > div {
    transition: all 0.3s ease-in-out;
    flex: 1 1 100%;
  }
  > div:nth-child(3) {
    transform: translate(100%);
  }
}

.showResults {
  > div:nth-child(2) {
    transform: translate(-100%);
  }
  > div:nth-child(3) {
    position: absolute;
    transform: translate(0%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .pageContainer {
    min-height: unset;
  }
  .showResults {
    align-items: flex-start;
  }
}
