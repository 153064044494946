.signInForm {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    height: 60px;
    max-width: 460px;
    border: 1px solid #ff8d4e;
    box-sizing: border-box;
    background: transparent !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.1em;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.8) !important;
    box-shadow: 0 0 0px 1000px #0d0d0d inset;
  }
  // input:focus {
  //   // box-shadow: 0 0 0px 1000px #000 inset;
  // }
  .p {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0;
  }

  .span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #ffffff;
    cursor: pointer;
  }
}

.alert {
  width: 460px;
  align-items: center;
  background: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.5s ease-in-out;
  :global {
    .anticon-close {
      color: #ffffff !important;
    }
    .anticon-close:hover {
      color: #ffffff !important;
    }
  }
}
