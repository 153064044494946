.btnPrimary {
  width: 204px;
  height: 50px;
  color: white;
  background: #156dc6;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
}
.btnPrimaryDis {
  width: 204px;
  height: 50px;
  color: white;
  background: grey;
  border: none;
  outline: none;
  cursor: default;
}
.btnSmall {
  width: 161px;
  height: 50px;
  color: white;
  background: #156dc6;
  border-radius: 10px;
  border: none;
  outline: none;
  // z-index: 1;
  cursor: pointer;
}

.btnFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 50px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  background-color: transparent;
  margin-bottom: 10px;
  cursor: pointer;
  flex: 0 0 auto;
  outline: none;
}

.btnUpload {
  width: 250px;
  height: 250px;
  background: #1e1e1e;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  span {
    margin: 0;
    margin-bottom: 10px;
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.06em;
    margin: 0;
  }
}

//scales btn
.btnScales,
.btnStar,
.btnEdit,
.btnDelete {
  width: 50px;
  height: 50px;
  background: #0d0d0d;
  border: 1px solid #156dc6 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  span {
    margin: 0;
  }
}

.btnStar {
  path {
    fill: #ffffff;
  }
}
//social button
.btnSocial {
  height: 50px;
  min-width: 223px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    margin: 0;
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    color: #ffffff;
    pointer-events: none;
  }
}

.btnEdit,
.btnDelete {
  path {
    fill: #ffffff !important;
  }
}

//popconfirm
.popConfirm {
  font-family: Futura;
  :global {
    .ant-popover-arrow,
    .ant-popover-inner {
      background: #0d0d0d;
      border-color: #156dc6 !important;
    }
    .ant-popover-message-title {
      color: #ffffff;
    }

    .ant-btn-primary {
      background: #156dc6 !important;
      border-color: #156dc6 !important;
    }
  }
  span {
    margin: 0;
  }
}
.notification {
  background: #0d0d0d;
  color: #ffffff;
  :global {
    .ant-notification-notice-close {
      color: #ffffff;
    }
  }
}

//responsive
@media screen and (max-width: 768px) {
  .btnFilter {
    border: none;
    margin: 0;
  }
  // .btnPrimary {
  //   width: 102px;
  // }
}

@media screen and (max-width: 480px) {
  .btnUpload {
    width: auto;
    height: auto;
    background: transparent;
    border: none;
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
