.auctionsContainer {
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  margin-bottom: 25px;
  // img {
  //   cursor: pointer;
  //   margin: 0 20px;
  // }
  span {
    transition: left 0.3s ease-in-out;
  }
}

.picsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  .AuctionImgBtnStyle {
    width: 239px;
    height: 99px;
    border-radius: 10px;
    border: 2px;
    background: transparent;
    border: 2px solid #4a4a4a;
    margin: 0 2%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .handleAuctionSelectStyle {
    background: rgba(255, 141, 78, 0.13);
    border: 2px solid #ff8d4e;
  }
  > div {
    cursor: pointer;
    margin: 0 20px;
  }
  div:first-child(1) {
    width: 5rem;
    height: 5rem * 0.59;
  }
  div:nth-child(2) {
    width: 5rem;
    height: 5rem * 0.38;
  }
}

@media screen and (max-width: 480px) {
  .picsContainer {
    margin-bottom: 8px;
    > div {
      margin: 0 15px;
      img {
        width: inherit;
        height: inherit;
      }
    }
    div:first-child {
      /*width: 65px;
      height: 40px;*/
    }
    div:nth-child(2) {
      width: 67px;
      height: 25px;
    }
  }
  .auctionsContainer {
    svg,
    span {
      height: 8.82px !important;
    }
  }
}
