.section2Container {
  width: 47%;
  margin-top: 2%;
  label {
    display: flex;
    font-family: "Futura";
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 12px;
    letter-spacing: 0.1em;
    color: #ffffff;
    margin: 10px 0;
    width: fit-content;
  }
}
.dropDown {
  background: #0d0d0d;
  :global {
    .ant-select-item {
      background: #0d0d0d;
      color: #ffffff;
    }
    .ant-select-item-option-active {
      background: #156dc6;
      display: flex !important;
    }
    .ant-select-item-option-content {
      display: flex;
    }
  }
  .carScale {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .section2Container {
    width: 100%;
  }
}
@media screen and (max-width: 767.98px) {
  .section2Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      label {
        display: block;
        flex: 1 1 100%;
        font-size: 0.5rem;
        line-height: 11px;
        margin-bottom: 5px;
        width: auto;
      }
    }

    .bottomSection {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 348px;

      div {
        label {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.5rem;
          line-height: 11px;
          margin-bottom: 5px;
          width: auto;
  
          span {
            display: inline-block;
            text-align: left;
          }
          span:first-child {
            max-width: 40px;
          }
        }
      }
    }
  }
}
