.wrapper {
  width: 100%;

  .dealerCarFilters {
    margin-bottom: 15px;
    text-align: left;

    :global {
      .ant-picker,
      .ant-picker-suffix,
      .ant-input,
      .ant-input-group-addon,
      .ant-picker-input > input,
      .ant-picker-clear,
      .ant-input-group-addon * {
        background-color: #0d0d0d !important;
        color: white !important;
      }

      .ant-select-selector {
        background-color: #0d0d0d !important;
        color: white !important;
        vertical-align: baseline !important;
        border: 1px solid #104a85 !important;
      }

      .ant-select-arrow {
        color: white !important;
      }

      .ant-btn-default {
        background-color: #0d0d0d !important;
        color: white !important;
        vertical-align: bottom !important;
        border: 1px solid #104a85 !important;
      }

      .ant-input-group-addon button {
        height: 31px;
      }

      .ant-picker,
      .ant-input-group-addon button,
      .ant-input {
        border: none;
        border-bottom: 1px solid;
      }
    }
  }

  :global {
    .ant-table {
      background: transparent !important;


    }

    .ant-table-wrapper {
      .ant-pagination-disabled .ant-pagination-item-link,
      .ant-pagination-disabled:hover .ant-pagination-item-link,
      .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
        background-color: #0d0d0d;
        color: #ffffff;
        line-height: 1.6em;
        text-align: center;
      }

      .ant-pagination-item-active {
        font-weight: 500;
        background: rgb(32, 32, 32);
        border-color: #1890ff;
        color: #ffffff;
      }

      .ant-table-pagination.ant-pagination {
        margin: 16px auto;
        width: auto;
        justify-content: center;

        .ant-pagination-total-text {
          color: white;
        }
      }
    }

    // .ant-table-thead {
    //   display: block;
    //   margin-bottom: -20px;
    // }
    // .ant-table-thead > tr {
    //   display: flex;
    //   gap: 10px;
    //   justify-content: flex-end;
    // }
    th {
      // width: 116px;
      // height: 40px;
      background: #104a85 !important;
      font-family: Futura !important;
      font-style: normal !important;
      font-weight: 500 !important;
      // font-size: 14px !important;
      //line-height: 19px !important;
      letter-spacing: 0.05em !important;
      color: #ffffff !important;
      text-align: center !important;
      padding: 5px 5px !important;
      //border: none !important;
    }

    // th:first-child {
    //   display: none;
    // }
    // .ant-table-tbody,
    // .ant-table-tbody tr {
    //   background: #585858 !important;
    //   :hover {
    //     background: #4b4a4a !important;
    //   }
    // }
    // .ant-table-tbody {
    //   display: block;
    //   padding-top: 20px;
    // }
    .ant-table-tbody > tr {
      // display: flex;
      // justify-content: flex-end;
      // gap: 10px;
      //margin-bottom: 10px;
      td {
        // width: 116px;
        // height: 90px !important;
        font-family: Roboto !important;
        font-style: normal !important;
        font-weight: normal !important;
        //font-size: 14px !important;
        //line-height: 16px !important;
        letter-spacing: 0.05em !important;
        color: #ffffff !important;
        // border: none !important;
        text-align: center !important;
        background: #0d0d0d !important;
        padding: 5px;

        img {
          // width: 100%;
          // height: 100%;
          object-fit: cover;
        }

      }

      td:first-child {
        //padding: 0 !important;
      }
    }
  }
}
