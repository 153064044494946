.signUpForm {
  .p {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0;
  }

  .span {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    input {
      background: transparent !important;
      width: 20px;
    }
  }
}

//
.alert {
  width: 460px;
  align-items: center;
  background: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.5s ease-in-out;
  :global {
    .anticon-close {
      color: #ffffff !important;
    }
    .anticon-close:hover {
      color: #ffffff !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .span {
    input {
      width: 35px !important;
    }
  }
}
