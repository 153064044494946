.homeSectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0f0f0f;
  color: #fff;
  position: relative;
  text-align: center;
  width: 100%;
  height: calc(100vh - 70px);
}

.contentWrapper {
  margin: 0 5% 5% 5%;
  width: 90%;
}

.mainSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 85vh;
  min-height: 895px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  margin-right: 50%;
}

.mainText {
  font-family: "Montserrat Armenian", sans-serif;
  font-size: 2.9rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.ctaButton {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 10px;
  width: 252px;
  height: 56px;
}

.carImageWrapper {
  width: 50rem;
  height: calc(50rem * 0.8);
  position: absolute;
  top: 20%;
  right: 0;
  z-index: 0;
  background-image: url("../../../assets/Images/home_section_bg.png");
  background-size: cover;
  background-position: center;
}

.carDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 999;
}

span {
  font-size: 1rem;
  font-family: "Montserrat Armenian", sans-serif;
  color: rgba(255, 255, 255, 0.6);
}

.moreDetailsLink {
  color: rgba(21, 109, 198, 1);
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 700;
  font-family: "Montserrat Armenian", sans-serif;

  &:hover {
    text-decoration: underline;
  }
}

.scrollDown {
  display: flex;
  margin-top: 20px;
}

.font {
  font-family: Montserrat arm;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 2rem;
}

.arrow,
.arrow2 {
  display: block;
  height: 20px;
  width: 20px;
  border-right: 5px solid white;
  border-bottom: 5px solid white;
  transform: rotateZ(45deg);
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  animation: go 3s infinite;
  margin-bottom: -10%;
}

.arrow2 {
  animation-delay: 0.5s;
  margin-bottom: -30px;
}

.detailsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

@keyframes go {
  0% {
    opacity: 1;
    transform: translateY(0) rotateZ(45deg);
  }
  100% {
    opacity: 0;
    transform: translateY(20px) rotateZ(45deg);
  }
}

@media (max-width: 575.98px) {
  .contentWrapper {
    width: 90%;
    margin: auto;
  }
  .mainSection {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 65vh;
    height: auto;
    margin: 5rem 0 0 0;
  }

  .textWrapper {
    margin-right: 0;
    text-align: center;
    align-items: center;
  }

  .mainText {
    font-size: 1.8rem;
    text-align: left;
  }

  .ctaButton {
    width: 100%;
    height: 2rem;
    border-radius: 4px;
    span {
      font-size: 0.75rem;
      font-family: Montserrat arm;
      font-weight: 400;
      text-align: left;
    }
    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .carImageWrapper {
    background-image: url("../../../assets/Images/home_section_bg_tablet.png");
    width: 20rem;
    height: calc(20rem * 0.6);
    margin-top: 20px;
    position: relative;
    top: unset;
  }

  .carDetails {
    flex-direction: column-reverse;
    align-items: center;
  }

  span {
    font-size: 1rem;
  }

  .moreDetailsLink {
    font-size: 0.9rem;
  }

  .scrollDown {
    margin-top: 3rem;
  }

  .font {
    font-size: 1.2rem;
    margin-left: 1rem;
  }

  .arrow,
  .arrow2 {
    height: 10px;
    width: 10px;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
  }

  .detailsRow {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    text-align: justify;
    padding: 0 1rem;
    a {
      font-size: 1rem;
      flex: 1 1 50%;
      box-sizing: border-box;
    }
  }
  .detailsRow a:nth-child(2) {
    text-align: right;
  }
  .detailsRow a:nth-child(4) {
    flex: 1 1 50%;
    flex-grow: 1;
    text-align: right;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .mainSection {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 68vh;
    height: auto;
    margin-top: 10rem;
  }

  .textWrapper {
    margin-right: 0;
    text-align: left;
  }

  .mainText {
    font-size: 1.8rem;
    text-align: left;
  }

  .ctaButton {
    width: 12rem;
    height: 1.5rem;
    font-size: 0.75rem;
    border-radius: 4px;
  }

  .carImageWrapper {
    background-image: url("../../../assets/Images/home_section_bg_tablet.png");
    width: 35rem;
    height: calc(35rem * 0.66);
    top: 45%;
    position: unset;
  }

  .carDetails {
    flex-direction: column-reverse;
    align-items: center;
  }

  span {
    font-size: 0.8rem;
  }

  .moreDetailsLink {
    font-size: 1rem;
  }

  .scrollDown {
    margin-top: 10px;
    width: 50%;
    justify-content: center;
  }

  .font {
    font-size: 0.75rem;
    margin-left: 1rem;
  }

  .arrow,
  .arrow2 {
    height: 12px;
    width: 12px;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
  }

  .detailsRow {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    text-align: justify;
    span {
      font-size: 1rem;
      flex: 1 1 50%;
      box-sizing: border-box;
    }
    a {
      flex: 1 1 50%;
      flex-grow: 1;
    }
  }
  .detailsRow span:nth-child(2) {
    text-align: right;
  }
  .detailsRow a:nth-child(4) {
    text-align: right;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mainSection {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 25%;
    min-height: 85vh;
  }

  .textWrapper {
    margin-right: 0;
    text-align: center;
  }

  .mainText {
    font-size: 1.5rem;
    text-align: left;
  }

  .ctaButton {
    border-radius: 4px;
    width: 12rem;
    height: 1.5rem;
    font-size: 0.75rem;
    font-family: Montserrat arm;
    font-weight: 400;
    text-align: left;
  }

  .carImageWrapper {
    width: 40rem;
    height: calc(40rem * 0.66);
    background-image: url("../../../assets/Images/home_section_bg_tablet.png");
    top: 35%;
  }

  .carDetails {
    flex-direction: row;
    align-items: center;
  }

  span {
    font-size: 0.75rem;
  }

  .moreDetailsLink {
    font-size: 1rem;
  }

  .scrollDown {
    margin-top: 10px;
    width: 50%;
  }

  .font {
    font-size: 0.75rem;
  }

  .arrow,
  .arrow2 {
    height: 14px;
    width: 14px;
    border-right: 3px solid white;
    border-bottom: 3px solid white;
  }

  .detailsRow {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mainSection {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 90vh;
  }

  .textWrapper {
    margin-right: 5%;
    text-align: center;
    margin-top: 20%;
  }

  .mainText {
    font-size: 2rem;
    text-align: left;
  }

  .ctaButton {
    width: 220px;
    height: 50px;
    font-size: 1rem;
  }

  .carImageWrapper {
    width: 42rem;
    height: calc(42rem * 0.8);
    top: 30%;
  }

  .carDetails {
    flex-direction: row;
    align-items: center;
  }

  span {
    font-size: 0.8rem;
  }

  .moreDetailsLink {
    font-size: 1.1rem;
  }

  .scrollDown {
    margin-top: 10px;
  }

  .font {
    font-size: 0.75rem;
    margin-left: 1rem;
  }

  .arrow,
  .arrow2 {
    height: 16px;
    width: 16px;
    border-right: 3px solid white;
    border-bottom: 3px solid white;
  }

  .detailsRow {
    flex-direction: row;
    align-items: center;
    width: 80%;
  }
}
@media (min-width: 1200px) and (max-width: 1919.98px) {
  .mainSection {
    min-height: 85vh;
  }

  .mainText {
    font-size: 2rem;
    text-align: left;
  }

  .carImageWrapper {
    width: 50rem;
    height: calc(35rem * 0.8);
    top: 30%;
  }
}
